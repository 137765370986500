/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import "./myBets.css";
import { userInstance } from "../../config/axiosConfig";
import { Modal } from "react-bootstrap";
import MyBetsPopUp from "../../components/mybetspopup/myBetsPopup";
import FainessPopup from "../../components/plinko/fainesspopup";

const MyBets = () => {
  const [myBets, setMyBets] = useState([]);
  const [totalBetsCount, setTotalBetsCount] = useState(0); // Changed to 0 for initialization
  const [betspopup, setBetspopup] = useState("");
  const [fairnessPopUp, setFairnessPopUp] = useState(false);

  const [params, setParams] = useState({
    skip: 0,
    limit: 10,
  });
  const [disable, setDisable] = useState({
    previous: true,
    next: false,
  });

  useEffect(() => {
    const fetchAllBets = async () => {
      const response = await userInstance().get(`/get-my-bet-history`, {
        params: params,
      });
      if (response?.data) {
        const { allBets, totalCount } = response.data;
        setMyBets(allBets);
        setTotalBetsCount(totalCount);
        setDisable({
          ...disable,
          previous: params.skip === 0,
          next: params.skip + 10 >= totalCount,
        });
      }
    };
    fetchAllBets();
  }, [params]);

  const handlePageMove = (val) => {
    let value = params.skip + val;
    if (value < 0 || value >= totalBetsCount) return;
    setParams({ ...params, skip: value });
  };

  const handlepopupclose = () => {
    setBetspopup("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hours >= 12;
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 becomes 12)

    const formattedHours = hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const period = isPM ? "PM" : "AM";

    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const year = date.getFullYear();

    return `${formattedHours}:${formattedMinutes} ${period} ${month}/${day}/${year}`;
  };

  const handleFairnessPopup = () => {
    setFairnessPopUp(true);
    setBetspopup(false);
  };

  const handleCloseFairness = () => {
    setFairnessPopUp(false);
  };

  return (
    <div className='my-bets'>
      <div className='container '>
        <h4 className='my-bets-title'>
          {" "}
          <MyBetsIcon /> My Bets
        </h4>

        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Game</th>
                <th style={{ textAlign: "center" }}>Bet ID</th>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "center" }}>Bet Amount</th>
                <th style={{ textAlign: "center" }}>Multiplier</th>
                <th style={{ textAlign: "center" }}>Payout</th>
              </tr>
            </thead>

            <tbody>
              {myBets?.map((bet) => (
                <tr key={bet.id}>
                  <td className=' text-white ' style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                    {bet?.gameType}
                  </td>
                  <td
                    onClick={() => setBetspopup(bet)}
                    className=' text-white '
                    style={{ fontWeight: "bold", cursor: "pointer", textAlign: "center" }}>
                    {bet.betId}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(bet.createdAt)}
                  </td>
                  <td style={{ textAlign: "center" }}>{bet.amount}</td>
                  <td style={{ textAlign: "center" }}>{bet.multiplier}</td>
                  <td style={{ textAlign: "center" }}>{bet.payout}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='d-flex justify-content-center bets-pagination'>
            <button
              disabled={disable.previous}
              onClick={() => handlePageMove(-10)}>
              Previous
            </button>
            <button disabled={disable.next} onClick={() => handlePageMove(+10)}>
              Next
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={betspopup}
        onHide={handlepopupclose}
        centered
        className=' plinko-game-page login-popup bets-popup'>
        <Modal.Header closeButton>
          <Modal.Title>Bet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MyBetsPopUp
            betspopup={betspopup}
            handleFairnessPopup={handleFairnessPopup}
            handlepopupclose={handlepopupclose}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={fairnessPopUp}
        onHide={handleCloseFairness}
        centered
        className='fairness-popup plinko-game-page login-popup'>
        <Modal.Header closeButton>
          <Modal.Title>Fairness</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FainessPopup />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyBets;

export const MyBetsIcon = () => {
  return (
    <svg
      fill='#a0a0a0'
      viewBox='0 0 64 64'
      className='svg-icon '
      height='18px'
      width='18px'>
      <path d='M.001 3.549v7.12h7.12v49.786h6.214c.778-3.122 3.556-5.398 6.866-5.398a7.07 7.07 0 0 1 6.856 5.348l.01.048h9.974c.778-3.122 3.556-5.398 6.866-5.398a7.07 7.07 0 0 1 6.856 5.348l.01.048h6.16V10.665h7.066v-7.12L.001 3.549Zm35.546 37.334h-17.76v-5.334h17.76v5.334Zm10.668-14.214H17.789v-5.334h28.426v5.334Z'></path>
    </svg>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Form} from "react-bootstrap";
import UserContext from "../../components/context/UserContext";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";

  
import "./settings.css";
import copy from "../../assets/images/game/copy-icon.png";
import { authInstance } from "../../config/axiosConfig";

const SettingsPage = () => {
  return (
    <div className="provably-fair-page settings-page">
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2 className="provably-fair-title">
            <SettingsIcon />
            Settings
          </h2>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="general">
          <Row>
            <Col sm={2} className="custom-tab-container">
              <Nav variant="pills" className="flex-column custom-tab-nav">
                <Nav.Item>
                  <Nav.Link eventKey="general">General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="security">Security</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="verify">Verify</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <div className="provably-fair-content">
                <Tab.Content>
                  <Tab.Pane eventKey="general">
                    <General />
                  </Tab.Pane>
                  <Tab.Pane eventKey="security">
                    <Security />
                  </Tab.Pane>

                  <Tab.Pane eventKey="verify">
                    <Verify />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default SettingsPage;

export const General = () => {
  return (
    <div>
      <div className="general-section mb-5">

          <h2>Username</h2>

        <div className=" py-3 ">
          <Form>
            <div className="mr-divider">
              <div className="w-50">
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <div className="plinko-input mt-2">
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Enter username"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="" style={{ width: "150px" }}>
                <Button className="btn plinko-bet-btn mt-4 ">Save</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div className="general-section ">

          <h2>Email</h2>


        <div className=" py-3 ">
          <Form>
            <div className="mr-divider">
              <div className="w-50">
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <div className="plinko-input mt-2">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter email"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="" style={{ width: "150px" }}>
                <Button className="btn plinko-bet-btn mt-4 ">Save</Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export const Security = () => {
  const [pass, setPass] = useState(true);
  const [secret, setSecret] = useState("");
  const [qrImage, setQrImage] = useState("");
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    password: "",
    code: "",
  });

  const { user } = useContext(UserContext);
  console.log("user", user);

  const getTwoFactorCode = async () => {
    try {
      const res = await authInstance().get("/enable-user-two-factor", {
        withCredentials: true,
        credential: "include",
      });

      setSecret(res.data.secret);
      setQrImage(res.data.qrImage);
      setTwoFactorAuthEnabled(res.data.twoFactorAuthEnabled);
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (fieldName, fieldValue) => {
    console.log("dsjadk", fieldName, fieldValue);
    setUserCredentials({
      ...userCredentials,
      [fieldName]: fieldValue,
    });
  };
  useEffect(() => {
    getTwoFactorCode();
  }, []);

  const handlePass = () => {
    setPass(!pass);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("userCredentials", userCredentials);
      const response = await authInstance().post(
        "/verify-user-two-factor",
        userCredentials
      );
      setUserCredentials({
        password: "",
        code: "",
      });

      setTwoFactorAuthEnabled(true);
      console.log("response", response);
      toast.success("Two factor authentication enabled", {
        toastId: "twofactor",
      });
    } catch (error) {
      toast.error("Invalid credentials", { toastId: "invalid" });

      console.log("error", error);
    }
  };

  const handleDisable = async (e) => {
    e.preventDefault();
    try {
      const response = await authInstance().post(
        "/disable-user-two-factor",
        userCredentials
      );
      setTwoFactorAuthEnabled(false);
      setUserCredentials({
        password: "",
        code: "",
      });
      console.log("response", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Text copied to clipboard", { id: "copy-success" });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <div>
      <div className="general-section mb-5">
        <h2>Password</h2>
        <div className="py-3">
          <Form>
            <div className="mr-divider">
              <div className="w-50">
                <Form.Group className="mb-3  relative">
                  <Form.Label>Old Password*</Form.Label>
                  <div className="plinko-input mt-2">
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter old password"
                    />
                    <span className="input-icon-settings" onClick={handlePass}>
                      <i
                        className={pass ? "las la-eye" : "las la-eye-slash"}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3  relative">
                  <Form.Label>New Password*</Form.Label>
                  <div className="plinko-input mt-2">
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter new password"
                    />
                    <span className="input-icon-settings" onClick={handlePass}>
                      <i
                        className={pass ? "las la-eye" : "las la-eye-slash"}
                      ></i>
                    </span>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3  relative">
                  <Form.Label>Current Password*</Form.Label>
                  <div className="plinko-input mt-2">
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Enter current password"
                    />
                    <span className="input-icon-settings" onClick={handlePass}>
                      <i
                        className={pass ? "las la-eye" : "las la-eye-slash"}
                      ></i>
                    </span>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="" style={{ width: "150px" }}>
                <Button className="btn plinko-bet-btn mt-4 ">
                  Change Password
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {twoFactorAuthEnabled ? (
        <>
          <div className="security-section">
            <div className="mr-divider">
              <h2>Disable 2FA</h2>

              <p>
                To keep your account extra secure leave a two factor
                authentication enabled.
              </p>
            </div>

            <div className="py-3 ">
              <Form onSubmit={handleDisable}>
                <div className="mr-divider">
                  <div className="w-50">
                    <Form.Group className="mb-3  relative">
                      <Form.Label>Password*</Form.Label>
                      <div className="plinko-input mt-2">
                        <Form.Control
                          type={pass ? "password" : "text"}
                          name="password"
                          // placeholder="Password"
                          value={userCredentials.password}
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                        <span
                          className="input-icon-settings"
                          onClick={handlePass}
                        >
                          <i
                            className={!pass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Two Factor Code*</Form.Label>
                      <div className="plinko-input mt-2">
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="XXXXXX"
                          value={
                            userCredentials.code}
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="" style={{ width: "150px" }}>
                    <Button className="btn plinko-bet-btn mt-4 " type="submit">
                      Disable
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="security-section">
            <div className="mr-divider">
              <h2>Two Factor</h2>

              <p>
                To keep your account extra secure leave a two factor
                authentication enabled.
              </p>
            </div>

            <div className="py-3 ">
              <Form onSubmit={handleSubmit}>
                <div className="mr-divider">
                  <div className="w-50">
                    <Form.Group>
                      <Form.Label>
                        Copy this code to your authenticator app
                      </Form.Label>
                      <div className="plinko-input">
                        <Form.Control type="text" name="secret" disabled value={secret} />
                        <div className="plinko-input-btn">
                          <Button
                            className="plinko-btn-copy"
                            onClick={() => handleCopy(secret)}
                          >
                            <img src={copy} alt="copy" />
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                    <p className="mx-auto mt-4">Don't let anyone see this!</p>
                    <img
                      // src="https://randomqr.com/assets/images/randomqr-256.png"
                      src={qrImage}
                      alt=""
                      className="h-25 w-25 mb-4 "
                    />
                    <Form.Group className="mb-3  relative">
                      <Form.Label>Password*</Form.Label>
                      <div className="plinko-input mt-2">
                        <Form.Control
                          type={pass ? "password" : "text"}
                          name="password"
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                        <span
                          className="input-icon-settings"
                          onClick={handlePass}
                        >
                          <i
                            className={!pass ? "las la-eye" : "las la-eye-slash"}
                          ></i>
                        </span>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Two Factor Code*</Form.Label>
                      <div className="plinko-input mt-2">
                        <Form.Control
                          type="text"
                          name="code"
                          placeholder="XXXXXX"
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="" style={{ width: "150px" }}>
                    <Button className="btn plinko-bet-btn mt-4 " type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

// const renderTooltip = (props, copied, type) => (
//   <Tooltip id="button-tooltip" {...props} placement="top">
//     {copied === type ? "Copied" : "Copy"}
//   </Toolti>
// );

export const Verify = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div className="security-section">
      <h2>Confirm Your Details</h2>

      <p>
        Please fill in your details & confirm your identity to unlock additional
        services. All Information is private & secure.
      </p>

      <Form>
        <div className="row">
          <Form.Group className="mb-3 col-md-6">
            <Form.Label>First Name*</Form.Label>
            <div className="plinko-input mt-2">
              <Form.Control
                type="text"
                name="first"
                placeholder="Enter first name"
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 col-md-6">
            <Form.Label>Last Name*</Form.Label>
            <div className="plinko-input mt-2">
              <Form.Control
                type="text"
                name="last"
                placeholder="Enter last name"
              />
            </div>
          </Form.Group>
        </div>
        <div className="no-of-rows mb-3">
          <Dropdown>
            <p className="labelTxt">Country*</p>
            <div className="plinko-input">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                disabled={false}
                className="form-control"
              >
                Select Country
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>India</Dropdown.Item>
                <Dropdown.Item>China</Dropdown.Item>
                <Dropdown.Item>Russia</Dropdown.Item>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        </div>

        <div className="row align-items-end date-of-birth mb-3">
          <Form.Group className="col-md-4">
            <Form.Label>Date of Birth*</Form.Label>
            <div className="plinko-input mt-2">
              <Form.Control type="text" name="Date" placeholder="DD" />
            </div>
          </Form.Group>

          <div className="no-of-rows col-md-4  ">
            <Dropdown>
              <div className="plinko-input">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="form-control"
                >
                  Month
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {months.map((item, index) => (
                    <Dropdown.Item key={index}>{item}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>

          <Form.Group className=" col-md-4 ">
            <div className="plinko-input ">
              <Form.Control type="text" name="YYYY" placeholder="YYYY" />
            </div>
          </Form.Group>
        </div>

        <Form.Group className="mb-3 ">
          <Form.Label>Residential Address</Form.Label>
          <div className="plinko-input mt-2">
            <Form.Control
              type="text"
              name="address"
              placeholder="Enter address"
            />
          </div>
        </Form.Group>

        <div className="row">
          <Form.Group className="mb-3 col-md-6">
            <Form.Label>City*</Form.Label>
            <div className="plinko-input mt-2">
              <Form.Control type="text" name="city" placeholder="Enter city" />
            </div>
          </Form.Group>

          <Form.Group className="mb-3 col-md-6">
            <Form.Label>Postal Code*</Form.Label>
            <div className="plinko-input mt-2">
              <Form.Control
                type="text"
                name="postal"
                placeholder="Enter code"
              />
            </div>
          </Form.Group>
        </div>

        <Form.Group className="mb-3 ">
          <Form.Label>Occupation*</Form.Label>
          <div className="plinko-input mt-2">
            <Form.Control
              type="text"
              name="first"
              placeholder="Enter occupation"
            />
          </div>
        </Form.Group>

        <Button className="btn plinko-bet-btn mt-4 ">Submit</Button>
      </Form>
    </div>
  );
};

export const SettingsIcon = () => {
  return (
    <svg
      fill="#a0a0a0"
      viewBox="0 0 64 64"
      class="svg-icon "
      height="20px"
      width="20px"
    >
      <path d="M55.441 32a26.082 26.082 0 0 0-.34-3.99l.02.15 8.16-6-7.12-12.32-9.254 4.054a23.83 23.83 0 0 0-6.502-3.784l-.164-.056L39.121 0h-14.24l-1.12 10.054c-2.554.98-4.76 2.276-6.71 3.874l.042-.034L7.84 9.84.72 22.16l8.16 6a25.007 25.007 0 0 0-.32 3.828V32c.012 1.366.128 2.694.34 3.99l-.02-.15-8.16 6 7.12 12.32 9.254-4.054a23.83 23.83 0 0 0 6.502 3.784l.164.056L24.88 64h14.24l1.12-10.054c2.554-.98 4.76-2.276 6.71-3.874l-.042.034 9.254 4.054 7.12-12.32-8.16-6c.192-1.146.308-2.474.32-3.828V32Zm-23.44 8.666A8.666 8.666 0 1 1 40.667 32c-.016 4.78-3.886 8.652-8.666 8.666H32h.002Z"></path>
    </svg>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthStore } from "./store/auth";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/header/header";
import Home from "./pages/home/home";
import { Route, Routes } from "react-router-dom";
import MyBets from "./pages/myBets/myBets";
import ProvablyFair from "./pages/provablyFair/provablyFair";

import SettingsPage from "./pages/settings/settings";
import Affiliate from "./pages/Affiliate/affiliate";

function AdminWrapper({ Component }) {
  return (
    <>
      <Header />
      <Component />
    </>
  );
}

function App() {
  const { user, signIn } = useAuthStore((state) => state);

  useEffect(() => {
    localStorage.removeItem("ballSpeed");
    if (!user) {
      signIn();
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AdminWrapper Component={Home} />} />
        <Route path="/my-bets" element={<AdminWrapper Component={MyBets} />} />
        <Route
          path="/provably-fair"
          element={<AdminWrapper Component={ProvablyFair} />}
        />
        <Route
          path="/settings"
          element={<AdminWrapper Component={SettingsPage} />}
        />

        <Route
          path="/affiliate"
          element={<AdminWrapper Component={Affiliate} />}
        />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;

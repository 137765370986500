import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./homepageTables.css";
import { useAuthStore } from "../../store/auth";
import { socket } from "../../config/socket";
import { userInstance } from "../../config/axiosConfig";

function formatTime(timestamp) {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert 0 hour to 12
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutesStr} ${ampm}`;
}

const HomeTables = () => {
  const { user } = useAuthStore((state) => state);
  const [limit, setLimit] = useState(10);
  const userId = user?.id || user?._id;
  const [tab, setTab] = useState(localStorage.getItem("betTab") || "High");
  const [bets, setBets] = useState([]);
  const [userBets, setUserBets] = useState([]);

  useEffect(() => {
    const getBets = async () => {
      try {
        const response = await userInstance().get("/getBets", {
          params: { tab, userId },
        });
        if (response?.data?.userBets) {
          const fetchedBets = response.data.userBets;
          setUserBets(fetchedBets);
          setBets(fetchedBets.slice(0, limit));
        }
      } catch (error) {
        console.log("Error", error);
      }
    };

    getBets();
  }, [userId, tab, limit]);

  const insertAndLimit = useCallback(
    (newObj) => {
      const amounts = userBets.map((obj) => obj.amount);
      const minValue = Math.min(...amounts);
      if (newObj.amount > minValue) {
        const updatedBets = [...userBets, newObj]
          .sort((a, b) => b.amount - a.amount)
          .slice(0, limit);
        setUserBets(updatedBets);
        return updatedBets;
      }
      return userBets;
    },
    [userBets, limit]
  );

  useEffect(() => {
    const handleRecentBets = (data) => {
      let updatedBets = [...userBets];
      const isValid =
        (tab === "My" && data?.userId?._id === userId) || tab === "All";
      if (tab === "High") {
        updatedBets = insertAndLimit(data);
      } else if (isValid) {
        updatedBets = [data, ...updatedBets].slice(0, limit);
      }
      setUserBets(updatedBets);
      setBets(updatedBets.slice(0, limit));
    };

    socket.on("recentBets", handleRecentBets);
    return () => {
      socket.off("recentBets", handleRecentBets);
    };
  }, [tab, limit, userBets, userId, insertAndLimit]);

  const handleSelect = (tabValue) => {
    localStorage.setItem("betTab", tabValue);
    setTab(tabValue);
  };

  const handleChangeLimit = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setLimit(newLimit);
    setBets(userBets.slice(0, newLimit));
  };

  return (
    <div className='plinko-game-tables plinko-game-page w-100 '>
      <div className='container '>
        <div className='d-flex  plinko-game-table-filter '>
          <select onChange={handleChangeLimit} value={limit}>
            <option value={0}>0</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
          </select>
        </div>

        <div className='tab-section'>
          <Tabs
            id='uncontrolled-tab-example'
            className='mb-3'
            onSelect={handleSelect}
            activeKey={tab}>
            <Tab eventKey='My' title='My Bets' disabled={!userId}>
              <MybetsTable bets={bets} />
            </Tab>
            <Tab eventKey='All' title='All Bets'>
              <AllBetsTable bets={bets} />
            </Tab>

            <Tab eventKey='High' title='High Rollers'>
              <HighRollersTable bets={bets} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default HomeTables;

export const MybetsTable = ({ bets }) => {
  return (
    <div className='game-table'>
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Game</th>
              <th style={{ textAlign: "center" }}>Time</th>

              <th style={{ textAlign: "center" }}>Bet Amount</th>
              <th style={{ textAlign: "center" }}>Multiplier</th>
              <th style={{ textAlign: "center" }}>Payout</th>
            </tr>
          </thead>

          <tbody>
            {bets?.map((bet) => (
              <tr key={bet.id}>
                <td className=' text-white ' style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                  {bet?.gameType}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatTime(bet.createdAt)}
                </td>

                <td style={{ textAlign: "center" }}>{bet.amount}</td>
                <td style={{ textAlign: "center" }}>{bet.multiplier}</td>
                <td
                  style={{
                    textAlign: "center",
                    color: bet.profit > 0 ? "#1fff20" : "#fff",
                  }}>
                  {bet.payout}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AllBetsTable = ({ bets }) => {
  return (
    <div className='all-bets-table'>
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Game</th>
              <th style={{ textAlign: "center" }}>User</th>
              <th style={{ textAlign: "center" }}>Time</th>
              <th style={{ textAlign: "center" }}>Bet Amount</th>
              <th style={{ textAlign: "center" }}>Multiplier</th>
              <th style={{ textAlign: "center" }}>Payout</th>
            </tr>
          </thead>

          <tbody>
            {bets?.map((bet) => (
              <tr key={bet.id}>
                <td className=' text-white ' style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                  {bet?.gameType}
                </td>
                <td style={{ fontWeight: "bold", textAlign: "center" }}>
                  {bet.userId?.name || "Hidden"}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatTime(bet.createdAt)}
                </td>
                <td style={{ textAlign: "center" }}>{bet.amount}</td>
                <td style={{ textAlign: "center" }}>{bet.multiplier}</td>
                <td style={{ textAlign: "center" }}>{bet.payout}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const HighRollersTable = ({ bets }) => {
  return (
    <div className='all-bets-table'>
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Game</th>
              <th style={{ textAlign: "center" }}>User</th>
              <th style={{ textAlign: "center" }}>Time</th>

              <th style={{ textAlign: "center" }}>Bet Amount</th>
              <th style={{ textAlign: "center" }}>Multiplier</th>
              <th style={{ textAlign: "center" }}>Payout</th>
            </tr>
          </thead>

          <tbody>
            {bets?.map((bet) => (
              <tr key={bet.id}>
                <td className=' text-white ' style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                  {bet?.gameType}
                </td>
                <td style={{ fontWeight: "bold", textAlign: "center" }}>
                  {bet.userId?.name || "Hidden"}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatTime(bet.createdAt)}
                </td>
                <td style={{ textAlign: "center" }}>{bet.amount}</td>
                <td style={{ textAlign: "center" }}>{bet.multiplier}</td>
                <td style={{ textAlign: "center" }}>{bet.payout}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

import { produce } from "immer";
import create from "zustand";
import { toast } from "react-toastify";
import { userInstance } from "../config/axiosConfig";

export const useAuthStore = create((setState, getState) => ({
  user: null,
  isAuthLoading: false,
  isAuth: false,
  isShow: false,

  setIsShow: async (value) => {
    try {
      setState((state) => ({ ...state, isShow: value }));
    } catch (error) {
      setState((state) => ({ ...state, isShow: false }));
      console.error("signInError", error);
    }
  },

  signIn: async () => {
    try {
      setState((state) => ({ ...state, isAuthLoading: true }));
      const res = await userInstance().get("", {
        withCredentials: true,
        credential: "include",
      });
      if (res.data) {
        setState(
          produce((state) => {
            state.user = res.data;
            state.isAuth = true;
            state.isAuthLoading = false;
          })
        );
      }
      setState((state) => ({ ...state, isLoading: false }));
    } catch (error) {
      setState((state) => ({ ...state, user: null, isAuth: false }));
      console.error("signInError", error);
    }
  },
  setUser: (user) => {
    try {
      setState(
        produce((state) => {
          state.user = user;
          state.isAuth = true;
          state.isAuthLoading = false;
        })
      );
    } catch (error) {
      toast.error("update user data");
      console.error("setUserError", error);
    }
  },
}));

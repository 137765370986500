import React from "react";
import "./DataTable.css";

function DataTable({ fairnessResult, fairnessData }) {
  return (
    <>
      <p className='tableHeading'>Final Result</p>
      <span className='payout-index'>
        Payout index: {fairnessResult?.payoutPath} ={" "}
        {fairnessResult?.multiplierIndex}
      </span>
      <div className='tableWrap mt-4'>
        <table>
          <tbody>
            <tr className='tr'>
              {fairnessResult?.multiplierRow?.map((elem, index) => (
                <td
                  key={index}
                  className={`td ${
                    index === fairnessResult?.multiplierIndex ? "marked" : ""
                  }`}>
                  <span className='cell-content'>{index}</span>
                </td>
              ))}
            </tr>
            <tr className='tr'>
              {fairnessResult?.multiplierRow?.map((elem, index) => (
                <td
                  key={index}
                  className={`td ${
                    index === fairnessResult?.multiplierIndex ? "marked" : ""
                  }`}>
                  <span className='cell-content'>{elem}</span>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <p className='tableHeading'>Casino Seeds to Bytes</p>

      {fairnessResult?.bytesArray?.map((elem, hmacIndex) => {
        return (
          <div className='tableWrap mt-4'>
            <p>
              HMAC_SHA256({fairnessData?.serverSeed},{fairnessData?.clientSeed}:
              {fairnessData?.nonce}:{hmacIndex})
            </p>
            <table>
              <tbody>
                <tr className='tr'>
                  {elem?.map((elem2, index) => (
                    <td key={index} className={`td`}>
                      <span className='cell-content'>{elem2?.hexValue}</span>
                    </td>
                  ))}
                </tr>
                <tr className='tr'>
                  {elem?.map((elem2, index) => (
                    <td key={index} className={`td`}>
                      <span className='cell-content'>{elem2?.numValue}</span>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}

      <p className='tableHeading mt-3'>Bytes to Number</p>

      <div className='tableWrap d-flex justify-content-between align-items-center'>
        {fairnessResult?.output?.map((elem, index) => (
          <div
            className={`tableSecond ${index !== 0 ? "ms-5" : ""}`}
            key={index}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={4}>
                    {" "}
                    ({elem?.bytes?.join(", ")}) - [0, ..., 1] ={" "}
                    {elem?.moveValue}
                  </td>
                </tr>
                {elem.bytes?.map((elem2, index2) => (
                  <tr>
                    <td
                      className='td'
                      style={{ paddingRight: "var(--space-4)" }}>
                      <span className='cell-content'>
                        {index2 === 0 ? "" : "+"}
                      </span>
                    </td>
                    <td className='td'>
                      <span className='cell-content'>0</span>
                    </td>
                    <td className='td' style={{ textAlign: "left" }}>
                      <span className='cell-content'>
                        {elem.arr[index2]?.substring(1)}
                      </span>
                    </td>
                    <td className='td'>
                      <span className='cell-content subtle'>
                        ({elem2} / (256 ^ {index2 + 1}))
                      </span>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className='td' style={{ paddingRight: "var(--space-4)" }}>
                    <span className='cell-content'>=</span>
                  </td>
                  <td className='td'>
                    <span className='cell-content weight-semibold'>{elem?.float[0]}</span>
                  </td>
                  <td className='td' style={{ textAlign: "left" }}>
                    <span className='cell-content weight-semibold'>
                      {elem?.float?.toString()?.substring(1)}
                    </span>
                  </td>
                  <td className='td'>
                    <span className='cell-content subtle'>(* 2)</span>
                  </td>
                </tr>
                <tr>
                  <td className='td' style={{ paddingRight: "var(--space-4)" }}>
                    <span className='cell-content'>=</span>
                  </td>
                  <td className='td'>
                    <span className='cell-content weight-semibold'>{elem?.actualMoveValue[0]}</span>
                  </td>
                  <td className='td' style={{ textAlign: "left" }}>
                    <span className='cell-content'>
                      {elem?.actualMoveValue?.toString()?.substring(1)}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
}

export default DataTable;

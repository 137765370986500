import React from "react";
import GameEngine from "../../components/plinko/GameEngine";
import HomeTables from "./homePageTables";

const Home = () => {
  return (
    <div>
      <GameEngine />
      <HomeTables />
    </div>
  );
};

export default Home;

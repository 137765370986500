import { toast } from "react-toastify";
import { authInstance } from "../../config/axiosConfig";
import { getCookie } from "./cookies";

export const handleLogout = async (user, setUser) => {
  if (user?.id || user?._id) {
    const tokenData = getCookie("refreshToken");
    if (tokenData) {
      await authInstance().post(
        "/logout",
        { refreshToken: tokenData },
        { withCredentials: true, credentials: "include" }
      );
    }
  }
  const betTab = localStorage.getItem("betTab");
  console.log("betTab", betTab);
  localStorage.clear();
  localStorage.setItem("betTab", betTab === "My" ? "High" : betTab);
  setUser();
  toast.success("Logout Successfully!");
  setTimeout(() => {
    window.location.href = "/";
  }, 2000);
};

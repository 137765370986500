import React from "react";

const GameEvent = () => {
  return (
    <div className="overview game-event">
      <h2>Plinko</h2>

      <p>
        For any game of Plinko, the generated outcome is based on the path of
        the falling ball. The game event determines the direction of the falling
        ball for each level in the falling process. Players can choose between 8
        and 16 pins of play, which determines the number of game events required
        to generate a complete path from top to bottom. Since there are only two
        possible directions (left or right) the translation is done by
        multiplying each float by 2, which maps to the following index:
      </p>

      <div className="code-container">
        <h3>{` // Index of 0 to 1 : left to right`}</h3>
        <h3 className="mb-3">const DIRECTIONS = [ left, right ];</h3>

        <h3>{`// Game event translation`}</h3>
        <h3>const direction = CARDS[Math.floor(float * 2)];</h3>
      </div>
    </div>
  );
};

export default GameEvent;

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import copy from "../../../assets/images/game/copy-icon.png";
import { plinkoInstance } from "../../../config/axiosConfig";
import { toast } from "react-toastify";

const UnhashedSeed = () => {
  const [hashServerSeed, setHashedServerdSeed] = useState("");
  const [unhashedSeed, setUnhashedSeed] = useState("N/A");
  const [error, setError] = useState("");

  const checkField = (value) => {
    if (value?.length !== 64) {
      setError("serverSeedHash must be at least 64 characters");
      return false;
    }
    setError("");
    return true;
  };

  const handleChangeSeed = (e) => {
    setHashedServerdSeed(e.target.value);
  };

  const handleBlur = (e) => {
    checkField(e.target.value);
  };

  const unhashedServerSeed = async () => {
    try {
      const isValid = checkField(hashServerSeed);
      if (!isValid) return;
      const response = await plinkoInstance().get("/unhashedServerSeed", {
        params: {
          hash: hashServerSeed,
        },
      });
      if (response.data.unhashedServerSeed) {
        setUnhashedSeed(response.data.unhashedServerSeed);
      }
    } catch (error) {
      if (error?.response?.data?.msg) {
        toast.error(error?.response?.data?.msg, {
          toastId: "errorMessage",
        });
      }
    }
  };

  return (
    <div className='unhashed-seed'>
      <Form.Group className='mb-10px'>
        <Form.Label>Server Seed (Hashed)</Form.Label>
        <div className='plinko-input plinko-nobg-input'>
          <Form.Control
            type='text'
            onChange={handleChangeSeed}
            onBlur={handleBlur}
          />
          <div className='plinko-change'>
            <Button onClick={unhashedServerSeed}>Unhash</Button>
          </div>
        </div>
        {error ? <p className='text-danger'>{error}</p> : null}
      </Form.Group>

      <Form.Group>
        <Form.Label>Server Seed</Form.Label>
        <div className='plinko-input'>
          <Form.Control type='text' disabled value={unhashedSeed} />
          <div className='plinko-input-btn'>
            <Button className='plinko-btn-copy '>
              <img src={copy} alt='copy' />
            </Button>
          </div>
        </div>
      </Form.Group>
    </div>
  );
};

export default UnhashedSeed;

import React, { useEffect, useState } from "react";
import "./myBetsPopup.css";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "../../assets/images/game/copy-icon.png";
// import { Svg } from "matter-js";
import { userInstance } from "../../config/axiosConfig";
import { useNavigate } from "react-router-dom";

const MyBetsPopUp = ({ betspopup, handleFairnessPopup, handlepopupclose }) => {
  const navigate = useNavigate();
  const [showProvableFairness, setShowProvableFairness] = useState(false);
  const [betData, setBetData] = useState({});
  const [copied, setCopied] = useState("");

  const getGameById = async (betId) => {
    const response = await userInstance().get("/get-game-by-id", {
      params: {
        betId,
      },
    });
    if (response?.data?.game) {
      setBetData(response.data.game);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hours >= 12;
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 becomes 12)

    const formattedHours = hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const period = isPM ? "PM" : "AM";

    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const year = date.getFullYear();

    return ` ${month}/${day}/${year}  at ${formattedHours}:${formattedMinutes} ${period} `;
  };

  const handleClick = () => {
    navigate("/provably-fair?tab=overview");
  };

  useEffect(() => {
    if (betspopup?.betId) getGameById(betspopup?.betId);
  }, [betspopup?.betId]);

  const handleCopy = (value) => {
    setCopied(value);
  };

  return (
    <div className="my-bets-popup">
      <div className="my-bets-popup-content">
        <div className="my-bets-popup-header">
          <h2>Plinko</h2>
          <span>
            <h3>
              ID {betData?.betId} <CopyIcon /> <AttachmentIcon />
            </h3>
          </span>

          <p>Placed by Hidden</p>

          <p>on {formatDate(betspopup?.createdAt)}</p>
        </div>

        <div className="my-bets-popup-body">
          <div className="body-content">
            <div className="body-content-div">
              <h2>Bet</h2>
              <p>{betData?.amount}</p>
            </div>
            <div className="body-content-div">
              <h2>Multiplier</h2>
              <p>{betData?.result}</p>
            </div>
            <div className="body-content-div">
              <h2>Payout</h2>
              <p>{betData?.payout}</p>
            </div>
          </div>

          <div className="multiplier-container">
            <div className="multiplier">
              <p>{betData?.result}</p>
            </div>
          </div>

          <div className="bets-popup-form">
            <Form>
              <Form.Group>
                <Form.Label>Risk</Form.Label>
                <div className="plinko-input">
                  <Form.Control
                    type="text"
                    name="risk"
                    value={betData?.risk}
                    readOnly
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Rows</Form.Label>
                <div className="plinko-input">
                  <Form.Control
                    type="text"
                    name="rows"
                    value={betData?.rows}
                    readOnly
                  />
                </div>
              </Form.Group>
            </Form>
          </div>

          <div className="d-flex justify-content-center ">
            <button className="bets-popup-btn">Play Plinko</button>
          </div>

          <div className="my-bets-popup-footer">
            <div
              className="my-bets-popup-footer-button"
              style={{ cursor: "pointer" }}
              onClick={() => setShowProvableFairness(!showProvableFairness)}
            >
              <h2>Provable Fairness</h2>
              {showProvableFairness ? <DownArrow /> : <ArrowIcon />}
            </div>

            {showProvableFairness && (
              <div className="my-bets-popup-footer-content">
                <Form>
                  <Form.Group>
                    <Form.Label>Server Seed</Form.Label>
                    <div className="plinko-input">
                      <Form.Control
                        type="text"
                        name="rows"
                        value={
                          betData?.unhashedServerSeed ||
                          "Seed hasn't been revealed yet"
                        }
                        readOnly
                      />
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label> Server Seed Hashed</Form.Label>
                    <div className="plinko-input">
                      <Form.Control
                        type="text"
                        value={betData?.serverSeed}
                        disabled
                      />
                      <div className="plinko-input-btn">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip(props, copied, "serverSeed")
                          }
                        >
                          <CopyToClipboard
                            onCopy={() => handleCopy("serverSeed")}
                            text={betData?.serverSeed}
                          >
                            <Button className="plinko-btn-copy">
                              <img src={copy} alt="copy" />
                            </Button>
                          </CopyToClipboard>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Group>

                  <div className="d-flex align-items-center gap-2 ">
                    <div className="w-75">
                      <Form.Group>
                        <Form.Label> Client Seed</Form.Label>
                        <div className="plinko-input">
                          <Form.Control
                            type="text"
                            value={betData?.clientSeed}
                            disabled
                          />
                          <div className="plinko-input-btn">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={(props) =>
                                renderTooltip(props, copied, "clientSeed")
                              }
                            >
                              <CopyToClipboard
                                text={betData?.clientSeed}
                                onCopy={() => handleCopy("clientSeed")}
                              >
                                <Button className="plinko-btn-copy">
                                  <img src={copy} alt="copy" />
                                </Button>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </Form.Group>
                    </div>

                    <div className="w-25">
                      <Form.Group>
                        <Form.Label>Nonce</Form.Label>
                        <div className="plinko-input">
                          <Form.Control
                            type="text"
                            value={betData?.nonce}
                            disabled
                          />
                          <div className="plinko-input-btn">
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={(props) =>
                                renderTooltip(props, copied, "nonce")
                              }
                            >
                              <CopyToClipboard
                                text={betData?.nonce}
                                onCopy={() => handleCopy("nonce")}
                              >
                                <Button className="plinko-btn-copy">
                                  <img src={copy} alt="copy" />
                                </Button>
                              </CopyToClipboard>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </Form>

                {betData?.unhashedServerSeed ? (
                  <p role="presentation" onClick={handlepopupclose}>
                    Verify Bet
                  </p>
                ) : (
                  <p role="presentation" onClick={handleFairnessPopup}>
                    Server seed needs to be changed to verify bet...
                  </p>
                )}
                <h3 role="presentation" onClick={handleClick}>
                  What is Provable Fairness?
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const renderTooltip = (props, copied, type) => (
  <Tooltip id="button-tooltip" {...props} placement="top">
    {copied === type ? "Copied" : "Copy"}
  </Tooltip>
);

export default MyBetsPopUp;

export const ArrowIcon = () => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 1024 1024"
      class="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z"
        fill="#fff"
      />
    </svg>
  );
};

export const DownArrow = () => {
  return (
    <svg
      fill="#fff"
      width="12px"
      height="12px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"></path>
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.24 2H11.3458C9.58159 1.99999 8.18418 1.99997 7.09054 2.1476C5.96501 2.29953 5.05402 2.61964 4.33559 3.34096C3.61717 4.06227 3.29833 4.97692 3.14701 6.10697C2.99997 7.205 2.99999 8.60802 3 10.3793V16.2169C3 17.725 3.91995 19.0174 5.22717 19.5592C5.15989 18.6498 5.15994 17.3737 5.16 16.312L5.16 11.3976L5.16 11.3024C5.15993 10.0207 5.15986 8.91644 5.27828 8.03211C5.40519 7.08438 5.69139 6.17592 6.4253 5.43906C7.15921 4.70219 8.06404 4.41485 9.00798 4.28743C9.88877 4.16854 10.9887 4.1686 12.2652 4.16867L12.36 4.16868H15.24L15.3348 4.16867C16.6113 4.1686 17.7088 4.16854 18.5896 4.28743C18.0627 2.94779 16.7616 2 15.24 2Z"
        fill="#a0a0a0"
      />
      <path
        d="M6.6001 11.3974C6.6001 8.67119 6.6001 7.3081 7.44363 6.46118C8.28716 5.61426 9.64481 5.61426 12.3601 5.61426H15.2401C17.9554 5.61426 19.313 5.61426 20.1566 6.46118C21.0001 7.3081 21.0001 8.6712 21.0001 11.3974V16.2167C21.0001 18.9429 21.0001 20.306 20.1566 21.1529C19.313 21.9998 17.9554 21.9998 15.2401 21.9998H12.3601C9.64481 21.9998 8.28716 21.9998 7.44363 21.1529C6.6001 20.306 6.6001 18.9429 6.6001 16.2167V11.3974Z"
        fill="#a0a0a0"
      />
    </svg>
  );
};

export const AttachmentIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="12.7835"
        y="2.38351"
        width="6"
        height="10"
        rx="3"
        transform="rotate(33.0385 12.7835 2.38351)"
        stroke="#a0a0a0"
        stroke-width="2"
        stroke-linecap="round"
      />
      <rect
        x="7.83558"
        y="6.32284"
        width="6"
        height="10"
        rx="3"
        transform="rotate(33.0385 7.83558 6.32284)"
        stroke="#a0a0a0"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export function random(min, max) {
    const random = Math.random()
    min = Math.round(min)
    max = Math.floor(max)
  
    return random * (max - min) + min
  }

export  const genrateClientSeed = (length = 20) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const queryString = () => {
  let query = window.location.search.substr(1);
  let resultval = {};
  query.split("&").forEach((part) => {
    let item = part.split("=");
    resultval[item[0]] = decodeURIComponent(item[1]);
  });
  return resultval;
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { plinkoInstance } from "../../../config/axiosConfig";
import debounce from "lodash/debounce";
import { bxBg } from "../../../utils/boxBackground";
import DataTable from "../../../components/plinko/dataTable";
import { queryString } from "../../../utils/random";

const Calculations = () => {
  const { clientSeed, serverSeed, nonce, plinkoRisk, plinkoRows } =
    queryString();

  const [calculationsData, setCalculationsData] = useState({
    clientSeed: clientSeed || "",
    serverSeed: serverSeed || "",
    nonce: Number(nonce || 0),
    rows: Number(plinkoRows || 16),
    risk: plinkoRisk || "Low",
  });

  console.log("calcuu", calculationsData);

  const [fairnessResult, setFairnessResult] = useState({});
  // const [error, setError] = useState("");

  // const [show, setShow] = useState(false);
  // const handleShow = () => {
  //   setShow(!show);
  // };
  const handleChange = (fieldName, fieldValue) => {
    console.log("dsjadk", fieldName, fieldValue);
    const copyCalculation = { ...calculationsData };
    copyCalculation[fieldName] = fieldValue;
    setCalculationsData(copyCalculation);
    if (copyCalculation?.serverSeed) {
      debouncedFetchData(copyCalculation);
    }
  };

  const handleVerify = async (copyFairness) => {
    try {
      // setError("");
      const response = await plinkoInstance().post("/verify", copyFairness);

      if (response?.data) {
        let outputToshow = response.data?.path
          .map((item) => (item === "left" ? "0" : "1"))
          .join(" + ");
        const dataResult = { ...response.data, payoutPath: outputToshow };
        setFairnessResult(dataResult);
      } else {
        // setError("No Data Found");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Debounce the API call function
  const debouncedFetchData = useCallback(debounce(handleVerify, 300), []);
  const handleNonce = (value) => {
    const copyCalculationData = {
      ...calculationsData,
      nonce: calculationsData.nonce + value,
    };
    if (copyCalculationData?.nonce < 0) return;
    setCalculationsData(copyCalculationData);
    if (calculationsData?.serverSeed) {
      debouncedFetchData(copyCalculationData);
    }
  };

  useEffect(() => {
    if (calculationsData?.serverSeed) {
      handleVerify(calculationsData);
    }
  }, []);

  return (
    <div className="calculations overview">
      <div className="calculations-content">
        <Form>
          <Form.Group>
            <Form.Label>Client Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control
                type="text"
                name="clientSeed"
                value={calculationsData?.clientSeed}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Server Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control
                type="text"
                name="serverSeed"
                value={calculationsData.serverSeed}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className="plinko-input">
              <Form.Control
                type="number"
                name="nonce"
                value={calculationsData.nonce}
                onChange={(e) =>
                  handleChange(e.target.name, Number(e.target.value))
                }
              />

              <div className="plinko-input-btn">
                <Button
                  className="plinko-btn-copy"
                  onClick={() => handleNonce(-1)}
                >
                  <DownArrow />
                </Button>
                <Button
                  className="plinko-btn-copy"
                  onClick={() => handleNonce(+1)}
                >
                  <UpArrow />
                </Button>
              </div>
            </div>
          </Form.Group>

          <div className="no-of-rows">
            <Dropdown>
              <p className="labelTxt">Risk</p>
              <div className="plinko-input">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled={false}
                  className="form-control"
                >
                  {" "}
                  {calculationsData.risk}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleChange("risk", "Low")}>
                    Low
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleChange("risk", "Medium")}>
                    Medium
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleChange("risk", "High")}>
                    High
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>
            </Dropdown>
            <Dropdown>
              <p className="labelTxt">Row</p>
              <div className="plinko-input">
                <Dropdown.Toggle
                  disabled={false}
                  variant="success"
                  id="dropdown-basic"
                  className="form-control"
                >
                  {calculationsData.rows}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[8, 9, 10, 11, 12, 13, 14, 15, 16].map((elem) => (
                    <Dropdown.Item onClick={() => handleChange("rows", elem)}>
                      {elem}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </Form>

        <div className="d-flex align-items-center justify-content-center calculations-box">
          <div
            className={`peg${
              bxBg[calculationsData.rows][fairnessResult?.multiplierIndex]
            } multiplier-provably-fair `}
          >
            {fairnessResult?.multiplier ? (
              <p>{fairnessResult?.multiplier}x</p>
            ) : null}
          </div>
        </div>
        {fairnessResult?.multiplier ? (
          <DataTable
            fairnessResult={fairnessResult}
            fairnessData={calculationsData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Calculations;

export const DownArrow = () => {
  return (
    <svg
      fill="#fff"
      width="16px"
      height="16px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"></path>
    </svg>
  );
};

export const UpArrow = () => {
  return (
    <svg
      fill="#fff"
      width="16px"
      height="16px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.256 23.481c0 0.269 0.106 0.544 0.313 0.75 0.412 0.413 1.087 0.413 1.5 0l14.119-14.119 13.913 13.912c0.413 0.413 1.087 0.413 1.5 0s0.413-1.087 0-1.5l-14.663-14.669c-0.413-0.412-1.088-0.412-1.5 0l-14.869 14.869c-0.213 0.212-0.313 0.481-0.313 0.756z"></path>
    </svg>
  );
};

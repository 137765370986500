import { io } from "socket.io-client";
import {landingServer } from "./keys";

//https://ludo-server-t3e66zpola-uc.a.run.app
const socket = io(landingServer, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  reconnection: false,
});
socket.on("connect", () => {
  console.log("connected");
});
socket.on("disconnect", () => {
  console.log("Disconnected");
});

export { socket };

import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { authInstance } from "../../config/axiosConfig";
import { toast } from "react-toastify";

const Forgot = ({ handleModal }) => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleForgetPassword = async () => {
    try {
      setLoader(true);
      const response = await authInstance().post("/forgot-password", {
        email: email,
      });
      setLoader(false);
      if (response?.data?.msg) {
        toast.success(response?.data?.msg, { toastId: "forgetsuccess" });
      }
    } catch (error) {
      setLoader(false);
      console.log("error", error);
    }
  };

  return (
    <div className='login total_bet_input'>
      <div className='login-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='login-form'>
                <h1>Forget</h1>
                <p>Please enter your email to forget your password</p>
                <div className='login-box'>
                  <Form>
                    <Form.Group controlId='formBasicFirst'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Enter your email '
                        name='email'
                        onChange={handleChangeEmail}
                      />
                    </Form.Group>
                    <div className='login-button'>
                      <Button
                        className='btn-gold plinko-bet-btn btn btn-contained btn-medium'
                        onClick={handleForgetPassword}
                        disabled={loader}>
                        {loader ? (
                          <Spinner
                            animation='border'
                            size='sm'
                            className='accept-btn-show-btn'
                          />
                        ) : (
                          "Forget"
                        )}
                      </Button>
                    </div>
                  </Form>
                  <p className='already' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;

import React from "react";
import "./gameInfoPopup.css";

const GameInfoPopUp = () => {
  return (
    <div className="game-info-pop">
      <div className="game-info-pop-content">
        <div className="game-info-content">
          <p>
            1. Configured modes via risk and multiplier determine different
            potential payouts.
          </p>
          <p>
            2. No gravity hacks by rotating device or monitors while playing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GameInfoPopUp;

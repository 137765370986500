import React from "react";

const Implementation = () => {
  return (
    <div className="overview implementation">
      <h2>Random Number Generation</h2>

      <p>
        For each verifiable bet, a client seed, a server seed, a nonce and a
        cursor are used as the input parameters for the{" "}
        <a
          target="_blank"
          href="https://en.wikipedia.org/wiki/HMAC"
          rel="noreferrer"
        >
          random number generation <LinkIcon />{" "}
        </a>
        function. This function utilises the cryptographic hash function{" "}
        <a
          href="https://en.wikipedia.org/wiki/HMAC"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          HMAC_SHA256 <LinkIcon />{" "}
        </a>
        to generate bytes which are then used as the foundation for how we
        generate provably fair random outcomes on our platform.
      </p>

      {/* function* bytesGenerator(options, bytesArray) {
  const { serverSeed, clientSeed, nonce } = options;
  let currentRound = 0;
  let currentRoundCursor = currentRound * 32;

  while (true) {
    const hmac = createHmac('sha256', serverSeed);
    hmac.update(`${clientSeed}:${nonce}:${currentRound}`);
    const buffer = hmac.digest();
    const hexValues = buffer.toString('hex').match(/.{1,2}/g);
    // Use map to create an array of hex values
    const hexArray = hexValues.map((hex, index) => ({ hexValue: hex, numValue: Number(buffer[index]) }));
    bytesArray.push(hexArray);

    while (currentRoundCursor < 32) {
      yield Number(buffer[currentRoundCursor]);

      currentRoundCursor += 1;
    }

    currentRoundCursor = 0;
    currentRound += 1;
  }
} */}

      <div className="code-container">
        <h3>
          {`// Random number generation based on following inputs: serverSeed,
          clientSeed, nonce and cursor`}
        </h3>
        <h3>function bytesGenerator(options) &#123;</h3>
        <h3>
          &nbsp;&nbsp;const &#123; serverSeed, clientSeed, nonce &#125; =
          options{" "}
        </h3>
        <h3>&nbsp;&nbsp;// Setup curser variables</h3>
        <h3>&nbsp;&nbsp;let currentRound = 0;</h3>
        <h3>&nbsp;&nbsp;let currentRoundCursor = currentRound * 32;</h3>

        <h3>
          &nbsp;&nbsp;// Generate outputs until cursor requirement fullfilled
        </h3>
        <h3>&nbsp;&nbsp;while (true) &#123;</h3>
        <h3>
          &nbsp;&nbsp;&nbsp;&nbsp;// HMAC function used to output provided
          inputs into bytes
        </h3>
        <h3>
          &nbsp;&nbsp;&nbsp;&nbsp;const hmac = createHmac('sha256', serverSeed);
        </h3>
        <h3>
          &nbsp;&nbsp;&nbsp;&nbsp;hmac.update(`&#36;&#123;clientSeed&#125;:&#36;&#123;nonce&#125;:&#36;&#123;currentRound&#125;`);
        </h3>
        <h3 className="mb-3">
          &nbsp;&nbsp;&nbsp;&nbsp;const buffer = hmac.digest();
        </h3>
        <h3>
          &nbsp;&nbsp;&nbsp;&nbsp;// Update curser for next iteration of loop
        </h3>
        <h3>
          &nbsp;&nbsp;&nbsp;&nbsp;while (currentRoundCursor &lt; 32) &#123;
        </h3>
        <h3>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;yield
          Number(buffer[currentRoundCursor]);
        </h3>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;currentRoundCursor += 1;</h3>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;&#125;</h3>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;currentRoundCursor = 0;</h3>
        <h3>&nbsp;&nbsp;&nbsp;&nbsp;currentRound += 1;</h3>
        <h3>&nbsp;&nbsp;&#125;</h3>
        <h3>&#125;</h3>
      </div>

      <h2>Server Seed</h2>
      <p>
        The server seed is generated by our system as a random 64-character hex
        string. You are then provided with an encrypted hash of that generated
        server seed before you place any bets. The reason we provide you with
        the encrypted form of the server seed is to ensure that the un-hashed
        server seed cannot be changed by the casino operator, and that the
        player cannot calculate the results beforehand.
      </p>

      <p>
        To reveal the server seed from its hashed version, the seed must be
        rotated by the player, which triggers the replacement with a newly
        generated one.
      </p>

      <p>
        From this point you are able to verify that the hashed server seed
        matches that of the un-hashed server seed. This process can be verified
        via our un-hashed server seed function found in the menu above.
      </p>

      <h2>Client Seed</h2>

      <p>
        The client seed belongs to the player and is used to ensure they have
        influence on the randomness of the outcomes generated. Without this
        component of the algorithm, the server seed alone would have complete
        leverage over the outcome of each bet.
      </p>
      <p>
        All players are free to edit and change their client seed regularly to
        create a new chain of random upcoming outcomes. This ensures the player
        has absolute control over the generation of the result, similar to
        cutting the deck at a brick and mortar casino.
      </p>
      <p>
        During registration, a client seed is created for you by your browser,
        to ensure your initial experience with the site goes uninterrupted.
        Whilst this randomly generated client seed is considered suitable, we
        highly recommend that you choose your own, so that your influence is
        included in the randomness.
      </p>
      <p>You can do this via the fairness modal.</p>

      <h2>Nonce</h2>

      <p>
        The nonce is simply a number that increments as every new bet is made.
        Due to the nature of the SHA256 cryptographic function, this creates a
        completely new result each time, without having to generate a new client
        seed and server seed.
      </p>
      <p>
        The implementation of nonce, ensures we remain committed to your client
        seed and server seed pair, whilst generating new results for each bet
        placed.
      </p>

      <h2>Cursor (Incremental Number)</h2>

      <p>
        We use 4 bytes of data to generate a single game result, and because
        SHA256 is limited to 32 bytes, we utilise this implementation of a
        cursor to give us the ability to create more game events without having
        to modify our provable fair algorithm.
      </p>

      <p>
        The cursor is only iterated over when the game being played requires the
        generation of more than 8 (32 bytes / 4 bytes) possible outcomes. For
        example: when we need to use more than 8 cards in a game of blackjack.
      </p>
      <p>
        The cursor starts as 0 and gets increased by 1 every time the 32 bytes
        are returned by the HMAC_SHA256 function. If we don’t require more than
        8 random numbers to be generated for the game events, then the curser
        does not increment as there is no need to generate any additional
        possible game outcomes.
      </p>
    </div>
  );
};

export default Implementation;

export const LinkIcon = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_429_11072)">
        <path
          d="M11 3.99994H4V17.9999C4 19.1045 4.89543 19.9999 6 19.9999H18C19.1046 19.9999 20 19.1045 20 17.9999V12.9999"
          stroke="#fff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 14.9999L20 3.99994"
          stroke="#fff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 3.99994H20V8.99994"
          stroke="#fff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_429_11072">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./ProvablyFair.css";
import Overview from "./components/overview";
import Calculations from "./components/calculations";
import GameEvent from "./components/gameEvent";
import Conversion from "./components/conversion";
import Implementation from "./components/implementations";
import UnhashedSeed from "./components/unhashedSeed";
import { queryString } from "../../utils/random";
import { useNavigate } from "react-router-dom";

const ProvablyFair = () => {
  const navigate = useNavigate();
  const { tab } = queryString();
  const [key, setKey] = useState(tab || "overview");
  const handleKey = (keyVal) => {
    console.log("key", keyVal);
    setKey(keyVal);
    navigate(`/provably-fair?tab=${keyVal}`);
  };

  return (
    <div className='provably-fair-page'>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <h2 className='provably-fair-title'>
            {" "}
            <ProvablyFairIcon /> Provably Fair
          </h2>
        </div>
        <Tab.Container
          id='left-tabs-example'
          activeKey={key}
          onSelect={handleKey}>
          <Row>
            <Col sm={2} className='custom-tab-container'>
              <Nav variant='pills' className='flex-column custom-tab-nav'>
                <Nav.Item>
                  <Nav.Link eventKey='overview'>Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='second'>Implementation</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='conversion'>Conversions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='game-event'>Game Event</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='seed'>Unhash Server Seed</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='calculations'>Calculation</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <div className='provably-fair-content'>
                <Tab.Content>
                  <Tab.Pane eventKey='overview'>
                    <Overview />
                  </Tab.Pane>
                  <Tab.Pane eventKey='second'>
                    <Implementation />
                  </Tab.Pane>
                  <Tab.Pane eventKey='conversion'>
                    <Conversion />
                  </Tab.Pane>
                  <Tab.Pane eventKey='game-event'>
                    <GameEvent />
                  </Tab.Pane>
                  <Tab.Pane eventKey='seed'>
                    <UnhashedSeed />
                  </Tab.Pane>
                  <Tab.Pane eventKey='calculations'>
                    <Calculations />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default ProvablyFair;

export const ProvablyFairIcon = () => {
  return (
    <svg
      fill='#a0a0a0'
      viewBox='0 0 64 64'
      class='svg-icon '
      height='20px'
      width='20px'>
      {" "}
      <path d='M54.727 15.006h3.12V8.37H34.654V2.61H27.99v5.758H4.746v6.637h4.505L0 37.452c0 7.037 5.704 12.741 12.741 12.741 7.038 0 12.741-5.704 12.741-12.741l-9.25-22.446h11.73v39.745h-9.303v6.638h25.165V54.75h-9.171V15.006h13.115l-9.25 22.446c0 7.037 5.703 12.741 12.74 12.741C58.297 50.193 64 44.489 64 37.452l-9.273-22.446ZM5.334 37.452l7.411-17.887 7.357 17.887H5.334Zm38.492 0 7.357-17.887 7.463 17.887h-14.82Z'></path>
    </svg>
  );
};

export const PLINKO = {
  FRICTION: 0.6,
  RESTITUTION: 0.9,
  RADIUS: 5,
  FILL: "#ffffff",
  BORDER: "#fff",
  stiffness: 1
};

PLINKO.DIAMETER = PLINKO.RADIUS * 2;

export const PARTICLE = {
  DENSITY: 1 / 10,
  FRICTION: 1,
  RESTITUTION: 1.7,
  RADIUS: 7,
  FILL: "#FF0000",
};

PARTICLE.DIAMETER = PARTICLE.RADIUS * 2;

import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useForm } from "react-hook-form";
import { LoginSchema } from "../../validationSchema/authSchema";
import { toast } from "react-toastify";
import "./login.css";
// import UserContext from "../../context/UserContext";
// import { useContext } from "react";
import { authInstance } from "../../config/axiosConfig";
import { useAuthStore } from "../../store/auth";

const Login = ({ handleModal }) => {
  // const {
  //   setIsCheckEmailVerification,
  //   setStoreEmailForReverification,
  // } = useContext(UserContext);
  const { setUser } = useAuthStore((state) => state);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // setValue,
  } = useForm({ mode: "onBlur", resolver: yupResolver(LoginSchema) });
  const [pass, setPass] = useState("password");
  const [loader, setLoader] = useState(false);
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const [userdata, setUserdata] = useState({});
  const [code, setCode] = useState("");
  const [isChecked] = useState(false);

  const handleLogin = async (values) => {
    try {
      setLoader(true);
      const response = await authInstance().post(
        "/login",
        {
          ...values,
          rememberMe: isChecked,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      const { status, data } = response;
      if (status === 200) {
        // toast.success("Login Successfully!");

        if (!data.user.twoFactorAuthEnabled) {
          reset();
          handleModal(false);
          console.log(data.user);
          setUser(data.user);
          // setTwoFactorAuthEnabled(data.user.twoFactorAuthEnabled);
          return;
        } else {
          setCode("");
          setTwoFactorAuthEnabled(data.user.twoFactorAuthEnabled);
          setUserdata(data.user);
        }
        reset();
        // handleModal(false);
        console.log(data.user);

        // setUser(data.user);
        // setTwoFactorAuthEnabled(data.user.twoFactorAuthEnabled);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.status !== 200) {
          const msg = e?.response?.data?.message.includes(
            `Cannot read properties of null (reading 'isEmailVerified')`
          )
            ? "User does not exist "
            : e?.response?.data?.message;
          handleModal(false);
          toast.error(msg, { toastId: "login" });
          if (e?.response?.data?.message === "Email is not verified") {
            // setStoreEmailForReverification(values.email);
            // setIsCheckEmailVerification(true);
            handleModal(false);
          }
        }
      }
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const response = await authInstance().post(
        "/validate-user-two-factor",
        {
          code: code,
          user_id: userdata?.id,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      const { status, data } = response;
      if (status === 200) {
        toast.success("Validate Successfully!");
        reset();
        handleModal(false);
        console.log(data.user);
        setUser(data.user);
        setTwoFactorAuthEnabled(data.user.twoFactorAuthEnabled);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.status !== 200) {
          const msg = e?.response?.data?.message.includes(
            `Cannot read properties of null (reading 'isEmailVerified')`
          )
            ? "User does not exist "
            : e?.response?.data?.message;
          // handleModal(false);
          toast.error(msg, { toastId: "login" });
          if (e?.response?.data?.message === "Email is not verified") {
            // setStoreEmailForReverification(values.email);
            // setIsCheckEmailVerification(true);
            // handleModal(false);
          }
        }
      }
    }
  };

  const handlePass = () => {
    setPass(!pass);
  };
  // const handleRemember = (e) => {
  //   setIsChecked(e?.target?.checked);
  // };

  return (
    <div className="login total_bet_input">
      <div className="login-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {twoFactorAuthEnabled ? (
                <>
                  <div className="login-form">
                    <h1>Enter the Code</h1>
                    {/* <p>Welcome, Please login to your account</p> */}
                    <div className="login-box">
                      <Form onSubmit={handleValidate}>
                        <Form.Group controlId="formBasicFirst">
                          {/* <Form.Label>Code</Form.Label> */}
                          <Form.Control
                            type="text"
                            placeholder="XXXXXX"
                            name="code"
                            autoComplete="off"
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </Form.Group>

                        <div className="login-button">
                          <Button
                            className="btn-gold plinko-bet-btn btn btn-contained btn-medium"
                            type="submit"
                          >
                            {loader ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="accept-btn-show-btn"
                              />
                            ) : (
                              "Validate"
                            )}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="login-form">
                    <h1>Log In</h1>
                    <p>Welcome, Please login to your account</p>
                    <div className="login-box">
                      <Form onSubmit={handleSubmit(handleLogin)}>
                        <Form.Group controlId="formBasicFirst">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your email "
                            name="email"
                            autoComplete="off"
                            {...register("email", {
                              required: "Enter Valid Email",
                            })}
                          />
                          {errors.email && (
                            <p className="error-msg">{errors.email.message}</p>
                          )}
                        </Form.Group>
                        <Form.Group
                          controlId="formBasicPassword"
                          style={{ position: "relative" }}
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type={pass ? "password" : "text"}
                            placeholder="Enter your password"
                            name="password"
                            autoComplete="off"
                            {...register("password", {
                              required: "Enter Valid Password",
                            })}
                          />
                          <span className="input-icon1" onClick={handlePass}>
                            <i
                              className={
                                pass ? "las la-eye" : "las la-eye-slash"
                              }
                            ></i>
                          </span>
                          {errors.password && (
                            <p className="error-msg">
                              {errors.password.message}
                            </p>
                          )}
                        </Form.Group>
                        <div className="forget-password">
                          <Button
                            className="forget-link"
                            onClick={() => handleModal("forgot")}
                          >
                            Forgot Password?
                          </Button>
                        </div>
                        <div className="login-button">
                          <Button
                            type="submit"
                            className="btn-gold plinko-bet-btn btn btn-contained btn-medium"
                          >
                            {loader ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="accept-btn-show-btn"
                              />
                            ) : (
                              "Login"
                            )}
                          </Button>
                        </div>
                        <div className="social-login">
                          <span>OR</span>
                          <div className="social-btns">
                            <Button className="twitch-btn">
                              <i className="fa fa-steam" /> Login with Steam
                            </Button>
                          </div>
                        </div>
                      </Form>
                      <p className="already">
                        Don't have an account yet?{" "}
                        <Button
                          className="forget-link"
                          onClick={() => handleModal("register")}
                        >
                          Register
                        </Button>{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

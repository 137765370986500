/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Tab, Form, Button, Dropdown } from "react-bootstrap";
import copy from "../../assets/images/game/copy-icon.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { plinkoInstance } from "../../config/axiosConfig";
import { useAuthStore } from "../../store/auth";
// import DataTable from "./dataTable";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { bxBg } from "../../utils/boxBackground";
// seeds
const FainessPopup = (data) => {
  const [copied, setCopied] = useState("");
  const { user, setUser } = useAuthStore((state) => state);
  const [error, setError] = useState("");

  console.log("user", user);
  const [clientSeed, setClientSeed] = useState("");

  const genrateClientSeed = (length = 20) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setClientSeed(result);
  };

  const handleChangeSeed = async () => {
    if (!clientSeed) {
      return;
    }

    const response = await plinkoInstance().put("/updateSeeds", { clientSeed });
    if (response?.data?.updateUser) {
      setUser(response?.data?.updateUser);
      genrateClientSeed();
    }
  };

  const handleClientSeed = (e) => {
    if (!e.target.value) setError("This field is required");
    else setError("");
    setClientSeed(e.target.value);
  };

  useEffect(() => {
    genrateClientSeed();
  }, []);

  return (
    <div className="fainess-popup-content">
      <div className="plinko-tab">
        <Tabs defaultActiveKey="seeds" id="uncontrolled-tab-example">
          <Tab eventKey="seeds" title="Seeds" className="helo">
            <Seeds
              data={data}
              setCopied={setCopied}
              copied={copied}
              user={user}
              activeClientSeed={user?.activeClientSeed}
              activeServerSeed={user?.activeServerSeedHash}
              nextServerSeed={user?.nextServerSeedHash}
              nonce={user?.nonce}
              clientSeed={clientSeed}
              handleChangeSeed={handleChangeSeed}
              setClientSeed={setClientSeed}
              handleClientSeed={handleClientSeed}
              error={error}
            />
          </Tab>
          <Tab eventKey="varify" title="Verify">
            <Varify data={data} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default FainessPopup;

const Seeds = ({
  data,
  setCopied,
  copied,
  activeClientSeed,
  activeServerSeed,
  nextServerSeed,
  nonce,
  clientSeed,
  handleChangeSeed,
  handleClientSeed,
  error,
}) => {
  const handleCopy = (value) => {
    setCopied(value);
  };

  return (
    <div className="seeds-content">
      <Form>
        <div className="tab-box">
          <Form.Group>
            <Form.Label>Active Client Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control type="text" value={activeClientSeed} disabled />
              <div className="plinko-input-btn">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "activeClientSeed")
                  }
                >
                  <CopyToClipboard
                    text={activeClientSeed}
                    onCopy={() => handleCopy("activeClientSeed")}
                  >
                    <Button className="plinko-btn-copy">
                      <img src={copy} alt="copy" />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Active Server Seed (Hashed)</Form.Label>
            <div className="plinko-input">
              <Form.Control type="text" value={activeServerSeed} disabled />
              <div className="plinko-input-btn">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(props, copied, "activeServerSeed")
                  }
                >
                  <CopyToClipboard
                    text={activeServerSeed}
                    onCopy={() => handleCopy("activeServerSeed")}
                  >
                    <Button className="plinko-btn-copy">
                      <img src={copy} alt="copy" />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Total bets made with pair</Form.Label>
            <div className="plinko-input">
              <Form.Control type="number" value={nonce} disabled />
              <div className="plinko-input-btn">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => renderTooltip(props, copied, "nonce")}
                >
                  <CopyToClipboard
                    text={nonce}
                    onCopy={() => handleCopy("nonce")}
                  >
                    <Button className="plinko-btn-copy">
                      <img src={copy} alt="copy" />
                    </Button>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </Form.Group>
        </div>
      </Form>
      <div className="seed-bottom mt-3">
        <h4>Rotate Seed Pair</h4>
        <Form.Group className="mb-10px">
          <Form.Label>New Client Seed</Form.Label>
          <div className="plinko-input plinko-nobg-input">
            <Form.Control
              type="text"
              value={clientSeed}
              onChange={handleClientSeed}
            />
            <div className="plinko-change">
              <Button onClick={handleChangeSeed}>Change</Button>
            </div>
          </div>
          {error ? <p className="text-danger">{error}</p> : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Next Server Seed (Hashed)</Form.Label>
          <div className="plinko-input">
            <Form.Control type="text" value={nextServerSeed} disabled />
            <div className="plinko-input-btn">
              <CopyToClipboard
                text={nextServerSeed}
                onCopy={() => handleCopy("nextServerSeed")}
              >
                <Button className="plinko-btn-copy">
                  <img src={copy} alt="copy" />
                </Button>
              </CopyToClipboard>
            </div>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

const Varify = () => {
  const [fairnessData, setFairnessData] = useState({
    clientSeed: "",
    serverSeed: "",
    nonce: 0,
    rows: 16,
    risk: "Low",
  });
  const [fairnessResult, setFairnessResult] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // const [show, setShow] = useState(false);
  const handleShow = () => {
    let url = "/provably-fair?tab=calculations";
    const params = [];

    if (fairnessData?.clientSeed) {
      params.push(`clientSeed=${fairnessData.clientSeed}`);
    }
    if (fairnessData?.serverSeed) {
      params.push(`serverSeed=${fairnessData.serverSeed}`);
    }
    if (fairnessData?.nonce) {
      params.push(`nonce=${fairnessData.nonce}`);
    }
    if (fairnessData?.risk) {
      params.push(`plinkoRisk=${fairnessData.risk}`);
    }
    if (fairnessData?.rows) {
      params.push(`plinkoRows=${fairnessData.rows}`);
    }

    if (params.length > 0) {
      url += "&" + params.join("&");
    }

    navigate(url);
  };
  const handleChange = (fieldName, fieldValue) => {
    const copyFairness = { ...fairnessData };
    copyFairness[fieldName] = fieldValue;
    setFairnessData(copyFairness);
    if (copyFairness?.serverSeed) {
      debouncedFetchData(copyFairness);
    }
  };

  const handleVerify = async (copyFairness) => {
    try {
      setFairnessResult({});
      setError("");
      const response = await plinkoInstance().post("/verify", copyFairness);
      if (response?.data) {
        let outputToshow = response.data?.path
          .map((item) => (item === "left" ? "0" : "1"))
          .join(" + ");
        const dataResult = { ...response.data, payoutPath: outputToshow };
        setFairnessResult(dataResult);
      } else {
        setError("No Data Found");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Debounce the API call function
  const debouncedFetchData = useCallback(debounce(handleVerify, 300), []);

  return (
    <div className="varify-content">
      <Form>
        <div className="tab-box">
          <Form.Group>
            <Form.Label>Client Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control
                type="text"
                name="clientSeed"
                value={fairnessData.clientSeed}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Server Seed</Form.Label>
            <div className="plinko-input">
              <Form.Control
                type="text"
                name="serverSeed"
                value={fairnessData.serverSeed}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nonce</Form.Label>
            <div className="plinko-input">
              <Form.Control
                type="number"
                name="nonce"
                value={fairnessData.nonce}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </div>
          </Form.Group>

          <div className="no-of-rows">
            <Dropdown>
              <p className="labelTxt">Risk</p>
              <div className="plinko-input">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled={false}
                  className="form-control"
                >
                  {fairnessData.risk}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleChange("risk", "Low")}>
                    Low
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleChange("risk", "Medium")}>
                    Medium
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleChange("risk", "High")}>
                    High
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>
            </Dropdown>
            <Dropdown>
              <p className="labelTxt">Row</p>
              <div className="plinko-input">
                <Dropdown.Toggle
                  disabled={false}
                  variant="success"
                  id="dropdown-basic"
                  className="form-control"
                >
                  {fairnessData?.rows}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[8, 9, 10, 11, 12, 13, 14, 15, 16].map((elem) => (
                    <Dropdown.Item onClick={() => handleChange("rows", elem)}>
                      {elem}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </div>
      </Form>
      <div className="varify-bottom-text d-flex  justify-content-center mb-3 ">
        {fairnessResult.multiplier && (
          <div className="final_result  ">
            <div
              className={`peg${
                bxBg[fairnessData?.rows][fairnessResult?.multiplierIndex]
              } multiplier-provably-fair `}
            >
              {fairnessResult.multiplier}
              {`x`}
            </div>
          </div>
        )}
        {error && <div className="final_result">Result Not Found !!</div>}
      </div>
      <div className="text-center text-bold">
        <p role="button" className="linkTxt" onClick={handleShow}>
          View Calculation Breakdown
        </p>
      </div>
    </div>
  );
};
const renderTooltip = (props, copied, type) => (
  <Tooltip id="button-tooltip" {...props} placement="top">
    {copied === type ? "Copied" : "Copy"}
  </Tooltip>
);

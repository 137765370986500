import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Button, Form } from "react-bootstrap";
// import btc from "../../assets/images/modal/btc.png";
// import eth from "../../assets/images/modal/eth.png";
// import usdt from "../../assets/images/modal/usdt.png";
// import doge from "../../assets/images/modal/doge.png";
// import monero from "../../assets/images/modal/monero.png";
// import ltc from "../../assets/images/modal/ltc.png";
// import dash from "../../assets/images/modal/dash.png";
// import usdc from "../../assets/images/modal/usdc.png";
import "./modal.css";
import { plinkoInstance } from "../../config/axiosConfig";
import { coinPayData } from "./coinPayData";
// import CopyToClipboard from "react-copy-to-clipboard";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const WalletModal = (props) => {
  const { handleHideWallet, showSpinner, setShowSpinner } = { ...props };

  return (
    <div className="bch-modal-content">
      <div className="bch-modal-tab">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <DepositIcon />
                <span>DEPOSIT</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                <WithdrawIcon />
                <span>WITHDRAW</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Deposit />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Withdraw
                showSpinner={showSpinner}
                setShowSpinner={setShowSpinner}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <div className="bch-modal-close" onClick={handleHideWallet}>
          <div className="close-icon">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModal;

const Deposit = () => {
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [qrCode, setqrCode] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = (e) => {
    e.preventDefault(); // Prevent the default action
    navigator.clipboard.writeText(qrCode).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => {
          setCopySuccess("");
        }, 2000); // Clear the success message after 2 seconds
      },
      () => {
        setCopySuccess("Failed to copy!");
      }
    );
  };

  const handleDepositAddress = async (chain) => {
    // setisLoading(true);
    const amount = parseFloat(0.02); //sending fixed amount because this field is neccessary(but does'nt matter while paying)

    // Ensure the price has a minimum value if it's very small
    const minimumValidAmount = 0.01; // Adjust this if needed
    const price =
      amount < minimumValidAmount
        ? minimumValidAmount.toFixed(2)
        : amount.toFixed(2);

    try {
      const response = await plinkoInstance().post(
        "/ccpayment/depositaddress",
        {
          coinId: selectedCoin.coinId, //need to make it dynamic
          price: price,
          chain,
        }
      );
      // Log the entire response for debugging
      console.log("Full response:", response);
      // Check if response structure is as expected
      if (response.data && response.data.data.address) {
        console.log("Response DATA------>>>", response.data);

        if (response.data.data.address) {
          setqrCode(response.data.data.address);
          console.log("QR CODE", qrCode);
        } else {
          console.error(
            "Address not found in response data:",
            response.payload.resultData.data
          );
        }
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      // setisLoading(false);
      console.error("Error in getting deposit address:", error);
    }
  };

  useEffect(() => {
    if (selectedCoin) {
      // Fetch networks based on selected coin
      const networks = Object.keys(selectedCoin.networks);
      setNetworkOptions(networks);
      // Reset selected network when coin changes
      setSelectedNetwork("");
    } else {
      setNetworkOptions([]);
      setSelectedNetwork("");
    }
  }, [selectedCoin]);

  const handleCoinChange = (event) => {
    const coinId = parseInt(event.target.value, 10);
    const coin = coinPayData.find((c) => c.coinId === coinId);
    setSelectedCoin(coin || null);
  };

  const handleNetworkChange = (event) => {
    setSelectedNetwork(event.target.value);
    handleDepositAddress(event.target.value);
  };

  // const handlePayment = () => {
  //   if (selectedCoin && selectedNetwork) {

  //   } else {
  //     console.log('Please select both a coin and a network.');
  //   }
  // };

  //   const depositData = [
  //     {
  //       icon: btc,
  //       name: "Bitcoin",
  //       shortname: "BTC"
  //     },
  //     {
  //       icon: eth,
  //       name: "Ethereum",
  //       shortname: "ETH"
  //     },
  //     {
  //       icon: usdt,
  //       name: "Tether",
  //       shortname: "USDT"
  //     },
  //     {
  //       icon: usdc,
  //       name: "USDC",
  //       shortname: "USDC"
  //     },
  //     {
  //       icon: monero,
  //       name: "Monero",
  //       shortname: "Monero"
  //     },
  //     {
  //       icon: ltc,
  //       name: "Litecoin",
  //       shortname: "LTC"
  //     },
  //     {
  //       icon: dash,
  //       name: "Dash",
  //       shortname: "DASH"
  //     },
  //     {
  //       icon: doge,
  //       name: "Dogecoin",
  //       shortname: "DOGE"
  //     },
  //   ]

  return (
    <div className="bch-tab-pane-content">
      <Form>
        <div className="bch-balance">
          {/* <Form.Label>Your balance</Form.Label> */}
          {/* <div className="data-box">
              <div title="0.00000200" className="value-box">
                <span>0.00000200</span>
                <span>BCH</span>
              </div>
            </div> */}
          <div>
            <Form.Label htmlFor="coinSelect">Select Coin</Form.Label>
            <div className="data-box">
              <select
                id="coinSelect"
                onChange={handleCoinChange}
                value={selectedCoin ? selectedCoin.coinId : ""}
                className="customSelect"
              >
                <option value="">Select a Coin</option>
                {coinPayData.map((coin) => (
                  <option key={coin.coinId} value={coin.coinId}>
                    {coin.symbol}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Dropdown for network selection */}
          {selectedCoin && (
            <div>
              <Form.Label htmlFor="networkSelect">Select Network</Form.Label>
              <div className="data-box">
                <select
                  id="networkSelect"
                  onChange={handleNetworkChange}
                  value={selectedNetwork}
                  className="customSelect"
                >
                  <option value="">Select a Network</option>
                  {networkOptions.map((network) => (
                    <option key={network} value={network}>
                      {selectedCoin.networks[network]?.chainFullName ||
                        "Unknown Network"}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        {qrCode !== null ? (
          <div className="bch-bitcoin-address">
            <Form.Label>Your Deposit Address</Form.Label>

            <div className="bch-bitcoin-address-box">
              <div
                className="address-box"
                title="bitcoincash:qrvlgwtmc5rwnfaapn9w29f7tdxm7xx89y5l8wds2j"
              >
                <div className="address-copy">{qrCode}</div>
              </div>
              <Button type="button" className="copy-button">
                <div className="visible-content">
                  <button onClick={copyToClipboard}>
                    <CopyIcon />
                  </button>
                </div>
              </Button>
            </div>
            <div className="bch-qr">
              <a href="/" className="qr-code">
                <img
                  src={`https://image-charts.com/chart?chs=150x150&cht=qr&chl=${qrCode}&choe=UTF-8`}
                  alt="qr"
                />
              </a>
            </div>
          </div>
        ) : selectedCoin && selectedNetwork ? (
          <div className="bch-qr">
            <Spinner />
          </div>
        ) : (
          <div></div>
        )}

        {/* <Button className="modal-btn gradient-btn">Open Wallet</Button>
          <div className="deposit-btn">
            <Button className="modal-btn grey-btn" >
              <div className="deposit">
                Deposit
                <div className="deposite-img">
                  <img src={btc} alt="btc" />
                  <img src={eth} alt="eth" />
                  <img src={monero} alt="xmr" />
                  <img src={doge} alt="doge" />
                  <img src={usdt} alt="usdt" />
                </div>
                & More
              </div>
            </Button>
          </div>

          <Button className="modal-btn green-btn">Buy</Button> */}
      </Form>

      {/* Secont Step */}

      {/* <Form>
          <div className="bch-deposit">
            <div className="back-btn">
              <BackBtn />
            </div>
            <p> DEPOSIT WITH</p>
          </div>


          <div className="deposit-select-grid">
            <div className="deposit-select-box">
                <div className="deposit-select-list">
                  <div className="deposit-select-list-info">
                    <img src={btc} alt="" />
                    <p>Bitcoin</p>
                  </div>
                  <span>BTC</span>
                </div>
            </div>

            <div className="deposit-select-content">

              <div className="deposit-select-search">
                <SearchIcon/><input type="text" placeholder="Search" />
              </div>

              <div className="deposit-select-list-grid">
                {depositData.map((e, i) => (
                    <div className="deposit-select-list" key={i}>
                    <div className="deposit-select-list-info">
                      <img src={e.icon} alt="" />
                      <p>{e.name}</p>
                    </div>
                    <span>{e.shortname}</span>
                  </div>
                    )
                )
                }
                
              </div>

            </div>
          </div>
          
          <Button className="modal-btn gradient-btn" >Send Bitcoin</Button>
        </Form> */}
    </div>
  );
};

const Withdraw = ({ showSpinner, setShowSpinner }) => {
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [inputAddress, setInputAddress] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [realCoinValue, setRealCoinValue] = useState("");
  const [coinInUSD, setCoinInUSD] = useState();

  const [networkFee, setNetworkFee] = useState();
  const [minWithdrawal, setMinWithdrawal] = useState();

  const getEthPriceInUsd = async () => {
    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const data = await response.json();
      const ethPriceInUsd = data.ethereum.usd;
      // console.log(`The current price of Ethereum (ETH) in USD is: $${ethPriceInUsd}`);
      return ethPriceInUsd;
    } catch (error) {
      console.error("Error fetching ETH price:", error);
    }
  };
  useEffect(() => {
    getEthPriceInUsd().then((ethPriceInUsd) => {
      const nfUSD = parseFloat(0.001 * ethPriceInUsd)
        .toString()
        .slice(0, 5);
      const minWithdrawalUSD = parseFloat(0.002 * ethPriceInUsd)
        .toString()
        .slice(0, 5);
      setNetworkFee(nfUSD);
      setMinWithdrawal(minWithdrawalUSD);
    });
  }, []);

  useEffect(() => {
    if (selectedCoin) {
      // Fetch networks based on selected coin
      const networks = Object.keys(selectedCoin.networks);
      setNetworkOptions(networks);
      // Reset selected network when coin changes
      setSelectedNetwork("");
    } else {
      setNetworkOptions([]);
      setSelectedNetwork("");
    }
  }, [selectedCoin]);

  useEffect(() => {
    if (
      selectedCoin !== null &&
      selectedNetwork !== "" &&
      inputAddress !== "" &&
      inputAmount !== ""
    ) {
      console.log("Here");
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [selectedCoin, selectedNetwork, inputAddress, inputAmount]);

  const handleCoinChange = async (event) => {
    const coinId = parseInt(event.target.value, 10);
    const coin = coinPayData.find((c) => c.coinId === coinId);
    setSelectedCoin(coin || null);
    //case-------------- If user select coin,insert value,then change coin ------------------
    if (inputAmount !== "") {
      const response = await plinkoInstance().post("/ccpayment/getCoinInUSD", {
        coinId: event.target.value,
      });
      if (response.data.msg === "success") {
        const usdPrice = response.data.data[event.target.value];
        setCoinInUSD(usdPrice);
        const UsdEqCoin = parseFloat(inputAmount / usdPrice);
        setRealCoinValue(UsdEqCoin.toString());
      }
    }
  };
  const handleNetworkChange = (event) => {
    setSelectedNetwork(event.target.value);
  };
  const handleInputAddress = (event) => {
    setInputAddress(event.target.value);
  };
  const handleInputAmount = (event) => {
    setInputAmount(event.target.value);
    const UsdEqCoin = parseFloat(event.target.value / coinInUSD);
    setRealCoinValue(UsdEqCoin.toString());
  };

  const handleWithdrawal = async () => {
    try {
      setShowSpinner(true);
      const response = await plinkoInstance().post("/ccpayment/withdraw", {
        coinId: selectedCoin.coinId, //need to make it dynamic
        amount: selectedCoin.coinId === 1891 ? "0.002" : realCoinValue,
        chain: selectedNetwork,
        address: inputAddress,
      });
      // console.log(response.data)
      if (response.data.status === 10000) {
        toast.success("Withdrawal Initiated!, Wait for confirmation.");
      } else if (response.data.status === 14000) {
        const errorMessage = `Contact your Admin\nError: ${response.data.msg}`;
        toast.error(errorMessage, { toastId: "14000" });
        setShowSpinner(false);
      } else {
        toast.error(response.data.msg, { toastId: "not14000" });
        setShowSpinner(false);
      }
    } catch (error) {
      console.error("Error in withdrawal process:", error);
    }
  };

  const handleInputToUSD = async () => {
    try {
      if (selectedCoin) {
        const response = await plinkoInstance().post(
          "/ccpayment/getCoinInUSD",
          {
            coinId: selectedCoin.coinId,
          }
        );
        if (response.data.msg === "success") {
          const usdPrice = response.data.data[selectedCoin.coinId];
          setCoinInUSD(usdPrice);
        }
      } else {
        toast.error("Please select a coin");
      }
    } catch (error) {
      console.error("Error in withdrawal process:", error);
    }
  };
  return (
    <div className="bch-tab-pane-content">
      <Form>
        <div className="bch-balance">
          {/* <Form.Label>Your balance</Form.Label>
          <div className="data-box">
            <div title="0.00000200" className="value-box">
              <span>0.00000200</span>
              <span>BCH</span>
            </div>
          </div>*/}
        </div>

        <div>
          <Form.Label htmlFor="coinSelect">Select Coin</Form.Label>
          <div className="data-box">
            {/* <label htmlFor='coinSelect'>Select Coin:</label> */}
            <select
              id="coinSelect"
              onChange={handleCoinChange}
              value={selectedCoin ? selectedCoin.coinId : ""}
              className="customSelect"
            >
              <option value="">Select a Coin</option>
              {coinPayData.map((coin) => (
                <option key={coin.coinId} value={coin.coinId}>
                  {coin.symbol}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* Dropdown for network selection */}
        {selectedCoin && (
          <div>
            <Form.Label htmlFor="networkSelect">Select Network</Form.Label>
            {/* <label htmlFor='networkSelect'>Select Network:</label> */}
            <div className="data-box">
              <select
                id="networkSelect"
                onChange={handleNetworkChange}
                value={selectedNetwork}
                className="customSelect"
              >
                <option value="">Select a Network</option>
                {networkOptions.map((network) => (
                  <option key={network} value={network}>
                    {selectedCoin.networks[network]?.chainFullName ||
                      "Unknown Network"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div>
          <Form.Label>Address</Form.Label>
          <div className="bch-input-box">
            <input
              type="text"
              placeholder="Your address"
              className="form-control"
              value={inputAddress}
              onChange={handleInputAddress}
            />
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-between align-items-center">
            <Form.Label>Amount</Form.Label>
            {selectedCoin ? (
              <div>
                {realCoinValue ? realCoinValue.slice(0, 7) : 0}{" "}
                {selectedCoin.symbol}
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="bch-input-box bch-amount">
            <input
              type="text"
              placeholder="Amount in dollars"
              value={inputAmount}
              className="form-control"
              onChange={handleInputAmount}
              onFocus={handleInputToUSD}
            />
            {/* <div className="amount-btn">
              <Button className="amount-bet">MIN</Button>
              <Button className="amount-bet">MAX</Button>
            </div> */}
          </div>
        </div>
        {showSpinner ? (
          <Button
            className="btn plinko-bet-btn btn-contained btn-medium"
            disabled
          >
            <Spinner />
          </Button>
        ) : (
          <Button
            onClick={handleWithdrawal}
            className="btn plinko-bet-btn btn-contained btn-medium"
            disabled={btnDisable}
          >
            Withdraw
          </Button>
        )}
        <div>
          Minimum withdrawal is $ {minWithdrawal}.Your withdrawal will have ${" "}
          {networkFee} substracted from your remaining balance to cover the fee
          required to process the transaction.
        </div>
      </Form>
    </div>
  );
};

const DepositIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 26 20">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
          <rect
            stroke="#fff"
            stroke-width="2"
            x="1"
            y="1"
            width="24"
            height="18"
            rx="5"
          ></rect>
          <path
            d="M13,5 C13.5522847,5 14,5.44771525 14,6 L14,9 L17,9 C17.5522847,9 18,9.44771525 18,10 C18,10.5522847 17.5522847,11 17,11 L14,11 L14,14 C14,14.5522847 13.5522847,15 13,15 C12.4477153,15 12,14.5522847 12,14 L12,11 L9,11 C8.44771525,11 8,10.5522847 8,10 C8,9.44771525 8.44771525,9 9,9 L12,9 L12,6 C12,5.44771525 12.4477153,5 13,5 Z"
            fill="#fff"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const WithdrawIcon = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 26 20">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
          <rect
            stroke="#fff"
            stroke-width="2"
            x="1"
            y="1"
            width="24"
            height="18"
            rx="5"
          ></rect>
          <rect fill="#fff" x="8" y="9" width="10" height="2" rx="1"></rect>
        </g>
      </g>
    </svg>
  );
};

const CopyIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      style={{ width: "18px" }}
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M9,7 C10.1045695,7 11,7.8954305 11,9 L11,16 C11,17.1045695 10.1045695,18 9,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,9 C-1.3527075e-16,7.8954305 0.8954305,7 2,7 L9,7 Z M8.5,9 L2.5,9 C2.25454011,9 2.05039163,9.17687516 2.00805567,9.41012437 L2,9.5 L2,15.5 C2,15.7454599 2.17687516,15.9496084 2.41012437,15.9919443 L2.5,16 L8.5,16 C8.74545989,16 8.94960837,15.8231248 8.99194433,15.5898756 L9,15.5 L9,9.5 C9,9.22385763 8.77614237,9 8.5,9 Z M16,0 C17.1045695,-2.02906125e-16 18,0.8954305 18,2 L18,9 C18,10.1045695 17.1045695,11 16,11 L13,11 L13,9 L15.5,9 C15.7454599,9 15.9496084,8.82312484 15.9919443,8.58987563 L16,8.5 L16,2.5 C16,2.22385763 15.7761424,2 15.5,2 L9.5,2 C9.25454011,2 9.05039163,2.17687516 9.00805567,2.41012437 L9,2.5 L9,5 L7,5 L7,2 C7,0.8954305 7.8954305,2.02906125e-16 9,0 L16,0 Z"
          fill="#bcbcbc"
        ></path>
      </g>
    </svg>
  );
};

// const SearchIcon = () => {
//     return (
//   <svg
//       style={{
//         width: 18,
//         height: 18,
//         marginLeft: 15,
//         marginRight: 15,
//       }}
//       viewBox="0 0 18 18"
//     >
//       <path
//         fill="#999"
//         fillRule="nonzero"
//         d="M7 0a7 7 0 0 1 5.577 11.231l5.114 5.047.084.093a1 1 0 0 1-1.488 1.331l-5.136-5.065A7 7 0 1 1 7 0Zm0 2a5 5 0 1 0 0 10A5 5 0 0 0 7 2Z"
//       />
//     </svg>
//     );
//   };

// const BackBtn = () => {
//   return (
//     <svg width="100%" height="100%" viewBox="0 0 18 18">
//       <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <path
//           d="M4,4.44974747 L14,4.44974747 C14.5522847,4.44974747 15,4.89746272 15,5.44974747 C15,6.00203222 14.5522847,6.44974747 14,6.44974747 L4,6.44974747 C3.44771525,6.44974747 3,6.00203222 3,5.44974747 C3,4.89746272 3.44771525,4.44974747 4,4.44974747 Z"
//           fill="#999999"
//           transform="translate(9.000000, 5.449747) rotate(-315.000000) translate(-9.000000, -5.449747)"
//         ></path>
//         <path
//           d="M4,11.5208153 L14,11.5208153 C14.5522847,11.5208153 15,11.9685305 15,12.5208153 C15,13.0731 14.5522847,13.5208153 14,13.5208153 L4,13.5208153 C3.44771525,13.5208153 3,13.0731 3,12.5208153 C3,11.9685305 3.44771525,11.5208153 4,11.5208153 Z"
//           fill="#999999"
//           transform="translate(9.000000, 12.520815) scale(-1, 1) rotate(-315.000000) translate(-9.000000, -12.520815)"
//         ></path>
//       </g>
//     </svg>
//   );
// };

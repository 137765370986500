import React from "react";
// import { LinkIcon } from "./overview";

const Conversion = () => {
 

  // function* floatsGenerator(options, bytesArray) {
  //   const byteRng = bytesGenerator(options, bytesArray);

  //   while (true) {
  //     const bytes = Array(4)
  //       .fill(0)
  //       .map(() => byteRng.next().value);
  //     const arr = [];
  //     const float = bytes.reduce((result, value, i) => {
  //       const divider = 256 ** (i + 1);
  //       const partialResult = Number((value / divider).toFixed(12));
  //       arr.push(partialResult.toFixed(12));

  //       return (Number(result) + partialResult).toFixed(12);
  //     }, 0);
  //     yield { float, bytes, arr };
  //   }
  // }

  return (
    <div className='overview conversion'>
      <h2>Bytes to Floats</h2>
      <p>
        The output of the Random Number Generator (byteGenerator) function is a
        hexadecimal 32-byte hash. As explained under the cursor implementation,
        we use 4 bytes of data to generate a single game result. Each set of 4
        bytes are used to generate floats between 0 and 1 (4 bytes are used
        instead of one to ensure a higher level of precision when generating the
        float.) It is with these generated floats that we derive the formal
        output of the provable fair algorithm before it is translated into game
        events.
      </p>
      <div className='code-container'>
        <h3>
          {` // Convert the hash output from the rng byteGenerator to floats`}
          {/* (// Convert the hash output from the rng byteGenerator to floats) */}
        </h3>
        <h3>function floatsGenerator (options) &#123;</h3>

        <div className='px-3'>
          <h3>{`// Random number generator function`}</h3>
          <h3>const byteRng = bytesGenerator(options);</h3>
          <h3 className='mb-3'> while (true) &#123;</h3>
          <h3>
            {`// Declare & Populate bytes array with sets of 4 from RNG output`}
          </h3>
          <h3 className='mb-3'>
            {" "}
            const bytes = Array(4).fill(0).map(() =&gt; byteRng.next().value);
          </h3>
          <h3>{`// Return bytes as floats`}</h3>
          <h3>
            &nbsp;&nbsp;const float = bytes.reduce((result, value, i) =&gt;
            &#123;
          </h3>
          <h3>&nbsp;&nbsp;&nbsp;&nbsp;const divider = 256 ** (i + 1);</h3>
          <h3>
            &nbsp;&nbsp;&nbsp;&nbsp;const partialResult = value / divider;
          </h3>
          <h3>&nbsp;&nbsp;&nbsp;&nbsp;return result + partialResult;</h3>
          <h3>&nbsp;&nbsp;&#125;, 0)</h3>
          <h3>yield float;</h3>
          <h3>&#125;;</h3>
        </div>
        <h3>&#125;;</h3>
      </div>
      <h2>Floats to Game Events</h2>
      <p>
        Where the process of generating random outputs is universal for plinko, it's at this point in the game outcome generation where a unique
        procedure is implemented to determine the translation from floats to
        game events.
      </p>
      {/* <p>
        The randomly float generated is multiplied by the possible remaining
        outcomes of the particular game being played. For example: In a game
        that uses a 52 card deck, this would simply be done by multiplying the
        float by 52. The result of this equation is then translated into a
        corresponding game event. For games where multiple game events are
        required, this process continues through each corresponding 4 bytes in
        the result chain that was generated using the described byteGenerator
        function.
      </p> */}
      {/* <h2>Shuffle of Game Events</h2>
      <p>
        For games such as Keno, Mines and Video Poker, where outcomes cannot be
        duplicated, we then utilise the{" "}
        <a
          href='https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle'
          target='_blank'
          rel='noreferrer'>
          {" "}
          Fisher-Yates shuffle <LinkIcon />
        </a>{" "}
        algorithm. This procedure influences the conversion process from floats
        to game events because each time a game event is translated, the amount
        of possible remaining game event possibilities has been reduced for any
        remaining steps in the result chain.
      </p>
      <p>
        As an example, in video poker, there is at first 52 cards available in
        the complete deck, and therefore the first game event is translated by
        multiplying the float by 52. Once this card has been dealt, there is
        only 51 remaining cards in the deck, and therefore the second card
        translation is done by multiplying the second float generated by 51.
        This continues in the same fashion until all the game events required
        have been generated.
      </p>
      With regards to Mines and Keno, this is simply a matter of implementing
      the same process as explained with video poker but changing that to tiles
      or locations on the board or grid, ensuring that each game event
      generated, hasn’t already been done so beforehand in the chain of results. */}
    </div>
  );
};

export default Conversion;

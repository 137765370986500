/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState } from "react";
import {
  World,
  Engine,
  Render,
  Events,
  Composite,
  Bodies,
  Runner,
  Body,
} from "matter-js";
import ballAudio from "../../assets/sound/sounds/ball.wav";
import Plinko from "./game/plinko";
import ManualTabToBet from "./manualtabtobet";
import AutoTabToBet from "./autotabtobet";
import { PARTICLE } from "./constants/bodies";
import { plinkoInstance } from "../../config/axiosConfig";
// import livestats from "../../assets/images/game/live.png";
// import fairnessicon from "../../assets/images/game/fairnessicon.svg";
import "./index.css";
import {
  Form,
  Modal,
  Tabs,
  Tab,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import FainessPopup from "./fainesspopup";
import LiveStats from "./livestatspopup";
import click from "../../assets/audio/drop3.mp3";
import win from "../../assets/audio/win3.mp3";
import { getMultiplierSound } from "../../pages/plinko/game/config/multipliers";
import { useAuthStore } from "../../store/auth";
import RightSidePanel from "../RightSidePanel/RightSidePanel";
import rave from "../../assets/images/rave1.mp4";
import { socket } from "../../config/socket";
import { getCookie } from "../utils/cookies";
import { toast } from "react-toastify";
import PlaygroundPopup from "../playgroundPopup/playgroundpopup";
import GameInfoPopUp from "../gameInfoPopup/gameInfoPopup";
import { bxBg } from "../../utils/boxBackground";

const customPointObject = {
  8: {},
  9: {},
  10: {},
  11: {},
  12: {},
  13: {},
  14: {},
  15: {},
  16: {},
};

const customArray = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
  15: [],
};
const IdSContainer = [];

// forCreatingCustomArrayss

const creatingCustomArraysForPoints = (dropBall, multplierIndex, row) => {
  if (!customPointObject[row][multplierIndex]) {
    customPointObject[row] = JSON.parse(JSON.stringify(customArray));
  }
  if (!customPointObject[row][multplierIndex].includes(dropBall)) {
    customPointObject[row][multplierIndex].push(dropBall);
  }
  // console.log("customPoint", customPointObject);
};

const MS_IN_SECOND = 4000;
const FPS = 60;
let engine;
let particles;
let plinkos;
let clearChips;
let autoBet;
let activeInfinite;

const gravFrict = {
  0: { friction: 0.1, gravity: 0.3, restitution: 1.6 },
  50: { friction: 0.5, gravity: 1, restitution: 1.7 },
  100: { friction: 1, gravity: 1.7, restitution: 2 },
};

const colorCombinationWithArray = (rowsCount) => {
  const midValue = 16 / 2;
  const fillArray = Array.from({ length: rowsCount }, (_, index) => index + 1);
  console.log("fillArray");
};

function GameEngine() {
  const isTestMode = localStorage.getItem("testmode") === "true";
  const { user, setUser, setIsShow } = useAuthStore((state) => state);
  const [fairness, setFairness] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [stats, setStats] = useState(false);
  const [winMultiplierBetValue, setWinMultiplierBetValue] = useState();
  const [showPanel, setShowPanel] = useState(false);
  const [gameInfo, setGameInfo] = useState(false);
  const [raveMode, setRaveMode] = useState(false);

  const handleRave = () => {
    setRaveMode(!raveMode);
  };

  const handlePanel = () => {
    if (localStorage.getItem("showPanel")) {
      localStorage.removeItem("showPanel");
    } else {
      localStorage.setItem("showPanel", "open");
    }
    setShowPanel(!showPanel);
  };

  const intialGameState = {
    ROWS: 16,
    betdisabeled: false,
    plinkoradius: 4,
    particleradius: 8,
    ROW_ADJUSTMENT: 0.9,
    COL_ADJUSTMENT: 0.99,
    CANVAS_WIDTH: 600,
    CANVAS_HEIGHT: 600,
    CANVAS_COLOR: "transparent",
    TIMESTEP: MS_IN_SECOND / FPS,
    PARTICLE,
  };

  const gameStateObj = localStorage.getItem("gameStateObj");

  const [gameStates, setgameStates] = useState(
    gameStateObj ? JSON.parse(gameStateObj) : intialGameState
  );

  console.log("gameStates", gameStates);

  const [isGameRunning, setIsGameRunning] = useState(false);
  const [betAmount, setBetAmount] = useState(isTestMode ? 0 : 50);
  const [risk, setRisk] = useState(localStorage.getItem("riskType") || "Low");
  const [serverWinBoxes, setServerWinBoxes] = useState([]);
  const [autoManual, setAutoManual] = useState(false);
  const [numOfBets, changeNumOfBets] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [serverSeed, setServerSeed] = useState();
  const [serverNonce, setNonce] = useState();
  const [volume, setVolume] = useState(true);
  const [winTextResult, setWinTextResult] = useState();
  const [toggleAnimation, setToggleAnimation] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [lastMultipliers, setLastMultipliers] = useState([]);
  const [inputRangeSpeed, setInputRangeSpeed] = useState(
    localStorage.getItem("ballSpeed") || 50
  );
  const [testMode, setTestMode] = useState(isTestMode);

  const [rowsToMultipliers, setRowsToMultipliers] = useState(null);

  const [key, setKey] = useState("Manual");
  const firstTimeBalance = 1000;
  let activeAutoBets = false;

  const handleBallColor = (newColor) => {
    PARTICLE.FILL = newColor;
  };

  const handleSpeedChange = (event) => {
    const value = event?.target?.value;
    let typeValue = 50;
    if (value < 40) {
      typeValue = 0;
    } else if (value > 60) {
      typeValue = 100;
    }
    engine.gravity.y = gravFrict[typeValue]?.gravity;
    PARTICLE.FRICTION = gravFrict[typeValue]?.friction;
    PARTICLE.RESTITUTION = gravFrict[typeValue]?.restitution;
    localStorage.setItem("ballSpeed", typeValue);
    setInputRangeSpeed(typeValue);
  };

  const handlestatsClose = () => {
    setStats(false);
  };
  const handleCloseFairness = () => {
    setFairness(false);
  };

  const handleCloseGameInfo = () => {
    setGameInfo(false);
  };

  const handlestatsShow = () => {
    setStats(true);
  };
  const handleShow = () => {
    setFairness(true);
  };

  useEffect(() => {
    PARTICLE.FILL = localStorage.getItem("ballColor") || PARTICLE.FILL;
    if (localStorage.getItem("showPanel")) {
      setShowPanel(true);
    }

    // genrateClientSeed();
  }, []);

  const createCanvas = (gravity) => {
    const container = document.getElementById("plinko");
    container.innerHTML = "";
    engine = Engine.create(container);
    const ballSpeed = localStorage.getItem("ballSpeed");
    if (ballSpeed) {
      engine.gravity.y = gravFrict[ballSpeed]?.gravity;
      PARTICLE.FRICTION = gravFrict[ballSpeed]?.friction;
      PARTICLE.RESTITUTION = gravFrict[ballSpeed]?.restitution;
    }
    engine.gravity.y =
      gameStates?.ROWS === 8 || gameStates?.ROWS === 9 ? 2.4 : 1.7;
    const render = Render.create({
      element: container,
      bounds: {
        max: {
          y: gameStates.CANVAS_HEIGHT,
          x: gameStates.CANVAS_WIDTH,
        },
        min: {
          y: 0,
          x: 0,
        },
      },
      options: {
        background: gameStates.CANVAS_COLOR,
        hasBounds: true,
        width: gameStates.CANVAS_WIDTH,
        height: gameStates.CANVAS_HEIGHT,
        wireframes: false,
      },
      engine,
    });
    const runner = Runner.create();
    Runner.run(runner, engine);
    Render.run(render);
  };
  //Create particles
  const addBall = useCallback(
    (
      nonce,
      serverSeed,
      amount,
      transactionId,
      seed,
      riskName,
      dropPoint,
      multiplier
    ) => {
      console.log("ss", {
        serverSeed,
        amount,
        transactionId,
        seed,
        riskName,
        dropPoint,
        multiplier,
      });
      const ballSound = new Audio(ballAudio);
      ballSound.volume = 0.0;
      ballSound.currentTime = 0;
      ballSound.play();
      const initialBallPoint =
        dropPoint || Math.random() * (255 - 200 + 1) + 265;
      const ballColor = PARTICLE.FILL;

      const payload = {
        restitution: PARTICLE.RESTITUTION,
        friction: PARTICLE.FRICTION,
        label: `ball|${nonce}:${serverSeed}:${transactionId}:${amount}:${seed}:${riskName}:${dropPoint}:${multiplier}`,
        id: new Date().getTime(),
        frictionAir:
          gameStates?.ROWS === 8 || gameStates?.ROWS === 9 ? 0.07 : 0.06,
        collisionFilter: {
          group: -1,
        },
        render: {
          fillStyle: ballColor,
          strokeStyle: `${ballColor}80`,
          lineWidth: 5,
        },
        isStatic: false,
      };
      const ball = Bodies.circle(
        initialBallPoint,
        40,
        gameStates.particleradius,
        payload
      );
      Body.setDensity(ball, PARTICLE.DENSITY);
      Composite.add(engine.world, ball);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gameStates.ROWS, gameStates.particleradius]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps

  function countBalls(world) {
    return world.bodies.filter((body) => body.label.includes("ball|")).length;
  }

  async function onCollideWithMultiplier(ball, multiplier) {
    const checkTestMode =
      localStorage.getItem("testmode") === "true" ||
      localStorage.getItem("testmode") === true
        ? true
        : false;

    const ballValue = ball.label.split("|")[1];
    const gameData = ballValue.split(":");
    if (gameData.length === 0) return;
    const [
      nonce,
      serverSeed,
      transactionId,
      amount,
      seed,
      riskname,
      dropPoint,
      multiplierNum,
    ] = gameData || [];
    let pgdId = multiplier?.id;
    let pgd = multiplier.id;
    const actualPgd = bxBg[gameStates.ROWS][multiplier.id - 1];
    pgd = `peg${actualPgd}`;
    const accessMultiplier = document.getElementsByClassName(pgd)[0];
    const { innerText } = accessMultiplier || {};
    const multiplierValue = !checkTestMode ? `${multiplierNum}x` : innerText;
    console.log({
      dropPoint,
      multiplierNum,
    });

    // creatingCustomArraysForPoints(dropPoint,multiplier.id - 1,gameStates.ROWS)

    let computedStyle = null;
    if (innerText && window) {
      computedStyle = window?.getComputedStyle(accessMultiplier);
    }
    // Log the computed background color

    const multiplierSong = new Audio(getMultiplierSound(multiplierValue));
    multiplierSong.currentTime = 0;
    multiplierSong.volume = 0.2;
    multiplierSong.play();
    setLastMultipliers((prev) => {
      if (prev.length === 6) {
        prev.pop();
        prev.unshift({
          multiplierValue,
          backgroundColor: computedStyle?.backgroundColor,
          color: computedStyle ? "#141414" : "#fff",
        });
      } else {
        prev.unshift({
          multiplierValue,
          backgroundColor: computedStyle?.backgroundColor,
          color: computedStyle ? "#141414" : "#fff",
        });
      }
      return prev;
    });
    setWinTextResult(multiplierValue);
    setToggleAnimation(true);
    setTimeout(() => {
      setToggleAnimation(false);
    }, 2000);
    setTimeout(() => {
      setWinTextResult();
    }, 2400);
    playAudio("win");
    setgameStates({
      ...gameStates,
      [pgd]: true,
    });
    setTimeout(() => {
      setgameStates({
        ...gameStates,
        [pgd]: false,
      });
    }, 300);

    ball.collisionFilter.group = 2;
    World.remove(engine.world, ball);

    const ballCount = countBalls(engine.world);
    if (ballCount <= 0) {
      setIsGameRunning(false);
      setIsDisabled(false);
    }

    //updating Using Wallet
    if (!checkTestMode) {
      await increaseXAmount(
        multiplierNum,
        amount,
        transactionId,
        serverSeed,
        nonce,
        seed,
        pgdId,
        riskname
      );
    }
  }

  // console.log("copy", sixteenArray);

  async function onBodyCollision(event) {
    const pairs = event.pairs;
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair;
      if (bodyA.label === "plinko" || bodyB.label === "plinko") {
        bodyA.render.lineWidth = 15;
        setTimeout(() => {
          bodyA.render.lineWidth = 0;
        }, 200);
      }
      if (bodyB.label.includes("ball") && bodyA.label.includes("pocket"))
        await onCollideWithMultiplier(bodyB, bodyA);
    }
  }

  const playAudio = (type) => {
    let sound = localStorage.getItem("sound");
    if (sound && sound === "true") {
      if (type) {
        console.log("type", type);
        const audioEl = document.getElementsByClassName(`audio-${type}`)[0];
        if (audioEl) {
          console.log("audio1", audioEl);
          audioEl.play();
          audioEl.currentTime = 0;
          audioEl.volume = 0.8;
          audioEl.loop = false;
          console.log("audio2", audioEl);
        }
      }
    }
  };

  const autoBetStarted = async (riskName) => {
    if (numOfBets > 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // if (isGameRunning) {
    //   toast.error("Game is runing", { toastId: "dropChips" });
    //   return;
    // }
    if (!testMode && !user) {
      toast.error("Please login first", { toastId: "login" });
      return;
    }
    if (!testMode) {
      if (user && user?.wallet < betAmount) {
        toast.error("Please recharge your wallet.", { toastId: "dropChips" });
        return;
      }
    }
    try {
      setIsGameRunning(true);
      // if (testMode) {
      //   playAudio("click");
      //   setNonce(0);
      //   addBall(0);
      //   setWinMultiplierBetValue(betAmount);
      //   return;
      // }
      const res = await plinkoInstance().post("/createPlinkoGameTest", {
        amount: betAmount,
        risk: risk.toLowerCase(),
        rows: gameStates.ROWS,
        mode: testMode,
      });
      playAudio("click");
      const {
        nonce,
        serverSeed,
        amount,
        transactionId,
        clientSeed: seed,
        multiplier,
        point,
      } = res.data;
      setNonce(nonce);
      setServerSeed(serverSeed);
      addBall(
        nonce,
        serverSeed,
        amount,
        transactionId,
        seed,
        risk,
        point,
        multiplier
      );
    } catch (error) {
      window.parent.postMessage(
        { type: "errorResponse", msg: error?.response?.data?.message },
        "*"
      );
      if (key === "Auto") {
        handleCancel();
      }
      setIsGameRunning(false);
      setgameStates({
        ...gameStates,
        betdisabeled: false,
      });
    }
  };
  const changeBetCount = (cnt) => {
    changeNumOfBets(parseInt(cnt));
  };
  var newnumOfBets = numOfBets;
  const increaseXAmount = async (
    multiplierNum,
    betAmnt,
    transactionId,
    serverSeed,
    nonce,
    clientSeed,
    multiplierId,
    riskname
  ) => {
    try {
      setWinMultiplierBetValue(betAmnt);
      const res = await plinkoInstance().post("/increaseXAmount", {
        param: {
          multiplier: multiplierNum,
          betAmnt: betAmnt,
          transactionId: transactionId,
          clientSeed: clientSeed,
          serverSeed: serverSeed,
          nonce: nonce,
          row: gameStates.ROWS,
          multiplierId: multiplierId,
          risk: riskname,
        },
      });
      // const finalData = { ...user, wallet: res.data.user.wallet };
      setUser(res.data.user);
      // setIsGameRunning(false);
    } catch (e) {
      console.log("Err", e);
    }
  };

  const dropChips = async (e) => {
    console.log("clicked the bet button");
    if (!user) {
      return setIsShow("login");
    }
    autoBet = numOfBets;
    if (e === "manualBet") {
      if (!testMode) {
        if (parseFloat(betAmount) < 0.1) {
          seterrorMessage("Bet amount should be equal or greater than 0.1");
          setTimeout(() => {
            seterrorMessage("");
          }, [3000]);
          return;
        }
        if (
          parseFloat(betAmount) === 0 ||
          betAmount === "" ||
          betAmount === null ||
          betAmount === undefined ||
          isNaN(betAmount)
        ) {
          seterrorMessage("Bet amount should be equal or greater than 0.1");
          setTimeout(() => {
            seterrorMessage("");
          }, [3000]);
          return;
        }

        if (user && user.wallet < betAmount) {
          toast.error("Please recharge your wallet.", { toastId: "dropChips" });
          return;
        }
      }
      // if (isGameRunning) {
      //   window.parent.postMessage("gameRunning", "*");
      //   return;
      // }

      // if (!testMode && !user) {
      //   toast.error("Please login first", { toastId: "login" });
      //   return;
      // }

      try {
        setIsGameRunning(true);
        setgameStates({
          ...gameStates,
          betdisabeled: true,
        });

        // if (testMode) {
        //   playAudio("click");
        //   setNonce(0);
        //   addBall(0);
        //   setWinMultiplierBetValue(betAmount);
        //   return;
        // }
        const res = await plinkoInstance().post("/createPlinkoGameTest", {
          amount: betAmount,
          risk: risk.toLowerCase(),
          rows: gameStates.ROWS,
          mode: testMode,
        });
        playAudio("click");
        const {
          nonce,
          serverSeed,
          amount,
          transactionId,
          clientSeed: seed,
          multiplier,
          point,
        } = res.data;
        setNonce(nonce);
        setServerSeed(serverSeed);

        addBall(
          nonce,
          serverSeed,
          amount,
          transactionId,
          seed,
          risk,
          point,
          multiplier
        );
      } catch (error) {
        window.parent.postMessage(
          { type: "errorResponse", msg: error?.response?.data?.message },
          "*"
        );
        setIsGameRunning(false);
        setgameStates({
          ...gameStates,
          betdisabeled: false,
        });
      }
    }
    console.log("gameState", gameStates.betdisabeled);
    if (e === "autoBet") {
      if (!testMode) {
        if (parseFloat(betAmount) < 0.1) {
          seterrorMessage("Bet amount should be equal or greater than 0.1");
          setTimeout(() => {
            seterrorMessage("");
          }, [3000]);
          return;
        }

        if (
          parseFloat(betAmount) === 0 ||
          betAmount === "" ||
          betAmount === null ||
          betAmount === undefined ||
          isNaN(betAmount)
        ) {
          seterrorMessage("BBet amount should be equal or greater than 0.1");
          setTimeout(() => {
            seterrorMessage("");
          }, [3000]);
          return;
        }
        // if (!testMode && !user) {
        //   toast.error("Please login first", { toastId: "login" });
        //   return;
        // }

        if (user && user?.wallet < betAmount) {
          toast.error("Please recharge your wallet.", { toastId: "dropChips" });
          return;
        }
      }

      if (
        autoBet === 0 ||
        numOfBets === 0 ||
        isNaN(newnumOfBets) ||
        isNaN(numOfBets)
      ) {
        localStorage.setItem("activeInfinite", true);
      }
      localStorage.setItem("hide", true);
      setIsCancel(true);
      activeInfinite = localStorage.getItem("activeInfinite");
      clearChips = setInterval(async () => {
        if (autoBet > 0) {
          autoBet = autoBet - 1;
          changeNumOfBets(autoBet);
        } else {
          changeNumOfBets(autoBet);
        }
        await autoBetStarted(risk);
        if (
          (autoBet === 0 && (!activeInfinite || activeInfinite !== "true")) ||
          (!testMode && user.wallet < betAmount)
        ) {
          setIsCancel(false);
          localStorage.setItem("hide", false);
          clearInterval(clearChips);
        }
      }, 1000);
    }
  };

  const handleCancel = () => {
    setIsCancel(false);
    localStorage.setItem("hide", false);
    // setIsGameRunning(false);
    changeNumOfBets(numOfBets);
    clearInterval(clearChips);
    localStorage.removeItem("activeInfinite");
    setgameStates({
      ...gameStates,
      betdisabeled: false,
    });
  };

  const handleTab = (e) => {
    if (isCancel) return;
    setKey(e);
    if (e === "Auto") {
      localStorage.removeItem("hide");
    } else if (e === "Manual") {
      setIsCancel(false);
      changeNumOfBets(numOfBets);
      clearInterval(clearChips);
      localStorage.removeItem("activeInfinite");
      setgameStates({
        ...gameStates,
        betdisabeled: false,
      });
    }
  };

  function binomialCoefficient(rows, k) {
    function factorial(x) {
      if (x === 0) return 1;
      return x * factorial(x - 1);
    }
    return factorial(rows) / (factorial(k) * factorial(rows - k));
  }

  function calculateProbabilities(n) {
    const baseProbabilities = [];
    for (let k = 0; k <= n; k++) {
      const prob =
        binomialCoefficient(n, k) * Math.pow(0.5, k) * Math.pow(0.5, n - k);
      baseProbabilities.push(prob);
    }
    baseProbabilities.map((p) => p * 100);

    // Define percentage reduction for side values
    const reductionPercentage = 0.1; // 10% reduction

    // Identify side values (e.g., first and last 2 indices)
    const sideIndices = [];

    sideIndices.push(0); //
    sideIndices.push(1); //Indices for the side values
    if (baseProbabilities.length > 12) {
      sideIndices.push(2);
      sideIndices.push(baseProbabilities.length - 3);
    }
    sideIndices.push(baseProbabilities.length - 2);
    sideIndices.push(baseProbabilities.length - 1);

    // Calculate reduced probabilities for side values
    const reducedSideProbabilities = baseProbabilities.map((prob, index) => {
      if (sideIndices.includes(index)) {
        return prob * (1 - reductionPercentage); // Reduce by 10%
      }
      return prob;
    });

    // Calculate total reduction amount
    const totalReductionAmount = baseProbabilities.reduce(
      (sum, prob, index) => {
        if (sideIndices.includes(index)) {
          return (
            sum + (baseProbabilities[index] - reducedSideProbabilities[index])
          );
        }
        return sum;
      },
      0
    );

    // Identify mid values indices
    const midIndices = baseProbabilities.map(
      (_, index) => !sideIndices.includes(index)
    );
    const totalMid = midIndices.reduce((sum, isMid, index) => {
      if (isMid) {
        return sum + baseProbabilities[index];
      }
      return sum;
    }, 0);

    // Adjust mid values
    const finalProbabilities = baseProbabilities.map((prob, index) => {
      if (midIndices[index]) {
        return prob + totalReductionAmount * (prob / totalMid); // Distribute reduced amount proportionally
      }
      return reducedSideProbabilities[index]; // Use reduced probabilities for side values
    });

    // Normalize adjusted probabilities so they sum to 100%
    const totalProbability = finalProbabilities.reduce(
      (sum, prob) => sum + prob,
      0
    );
    const normalizedProbabilities = finalProbabilities.map(
      (prob) => (prob / totalProbability) * 100
    ); // Convert to percentage

    // Round percentages to 4 decimal places
    const percentages = normalizedProbabilities.map((p) =>
      parseFloat(p.toFixed(4))
    );

    return percentages;
  }

  const changeRisk = async () => {
    try {
      let allRows = rowsToMultipliers;
      const nrow = gameStates?.ROWS;
      if (!allRows) {
        const result = await plinkoInstance().get("/getRowsToMultipliers");
        allRows = result?.data?.rowsToMultipliers;
        if (!allRows) return;
        setRowsToMultipliers(allRows);
      }
      const currentMultipliersRows = allRows[nrow][risk.toLowerCase()];
      const probabilities = calculateProbabilities(nrow);

      const finalMultiplierRow = currentMultipliersRows.map((elem, index) => {
        return {
          value: elem,
          chance: probabilities[index],
        };
      });

      // const probabilities = [];
      // for (let k = 0; k <= nrow; k++) {
      //   const prob =
      //     binomialCoefficient(nrow, k) *
      //     Math.pow(0.5, k) *
      //     Math.pow(0.5, nrow - k);
      //   probabilities.push(prob);
      // }

      // const houseEdge = 0.1;
      // const adjustedProbabilities = probabilities.map(
      //   (p) => p * (1 - houseEdge)
      // );
      // const minProbability = Math.min(...adjustedProbabilities);
      // const maxProbability = Math.max(...adjustedProbabilities);

      // const scaledProbabilities = adjustedProbabilities.map((p) => {
      //   const normalized =
      //     (p - minProbability) / (maxProbability - minProbability);
      //   return normalized * (1 - houseEdge) + minProbability;
      // });
      // const totalProbability = scaledProbabilities.reduce(
      //   (sum, p) => sum + p,
      //   0
      // );
      // const normalizedProbabilities = scaledProbabilities.map(
      //   (p) => (p / totalProbability) * 100
      // );

      // const finalMultiplierRow = currentMultipliersRows?.map((elem, index) => {
      //   return {
      //     value: elem,
      //     chance: normalizedProbabilities[index].toFixed(4),
      //   };
      // });
      setServerWinBoxes(finalMultiplierRow);
    } catch (error) {
      console.log("error", error);
    }
  };

  // const changeRows = async (e) => {
  //   console.log("condition", e);
  //   if (e === 8 || e === 9) {
  //     setgameStates({
  //       ...gameStates,
  //       plinkoradius: gameStates.plinkoradius + 0.5,
  //       ROWS: Number(e),
  //       particleradius: 15,
  //     });
  //   } else if (e === 10 || e === 11) {
  //     setgameStates({
  //       ...gameStates,
  //       plinkoradius: gameStates.plinkoradius + 0.5,
  //       ROWS: Number(e),
  //       particleradius: 14,
  //     });
  //   } else if (e === 12 || e === 13) {
  //     setgameStates({
  //       ...gameStates,
  //       plinkoradius: gameStates.plinkoradius + 0.5,
  //       ROWS: Number(e),
  //       particleradius: 12,
  //     });
  //   } else if (e === 14 || e === 15) {
  //     setgameStates({
  //       ...gameStates,
  //       plinkoradius: gameStates.plinkoradius + 0.5,
  //       ROWS: Number(e),
  //       particleradius: 8,
  //     });
  //   } else {
  //     setgameStates({
  //       ...gameStates,
  //       plinkoradius: 4,
  //       particleradius: 8,
  //       ROWS: Number(e),
  //     });
  //   }
  // };

  const changeRows = (e) => {
    const difference = 16 - e;
    const increaseRadius = difference > 0 ? difference / 2 : difference;
    const increaseParticleRadius = difference > 3 ? difference - 3 : 0;
    let gameStateObj = {
      ...gameStates,
      plinkoradius: 4 + increaseRadius,
      ROWS: Number(e),
      particleradius: 8 + increaseParticleRadius,
    };
    localStorage.setItem("gameStateObj", JSON.stringify(gameStateObj));
    setgameStates({
      ...gameStates,
      plinkoradius: 4 + increaseRadius,
      ROWS: Number(e),
      particleradius: 8 + increaseParticleRadius,
    });
  };

  const createPlinkos = (ROWS, r) => {
    console.log("sdjlakdjklasj", ROWS);
    const ROW_SPACING =
      (gameStates.CANVAS_HEIGHT / ROWS) * gameStates.ROW_ADJUSTMENT;
    const COL_SPACING =
      (gameStates.CANVAS_WIDTH / (ROWS + 2)) * gameStates.COL_ADJUSTMENT;
    setgameStates({
      ...gameStates,
      COL_SPACING,
    });
    const VERTICAL_MARGIN = ROW_SPACING * 1.5;
    const HORIZONTAL_OFFSET = COL_SPACING / 2;
    let id = 0;
    let row = 2;
    for (row; row < ROWS + 2; row += 1) {
      const differ =
        (ROWS + 2 - row) * HORIZONTAL_OFFSET + gameStates.COL_ADJUSTMENT + 4;

      for (let col = 0; col <= row; col += 1) {
        const x = col * COL_SPACING + differ;
        const y = VERTICAL_MARGIN + ROWS + (row - 2) * ROW_SPACING;
        const plinko = new Plinko({ id, x, y, r });
        plinkos[id] = plinko;
        plinko.addToEngine(engine.world);
        id += 1;

        if (row === ROWS + 1 && col > 0) {
          if (gameStates?.ROWS === 8 || gameStates?.ROWS === 9) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 40, 600, 60, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 10) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 35, 600, 60, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 11) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 32, 600, 55, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 12) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 30, 600, 50, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 13) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 28, 600, 48, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 14) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 25, 600, 45, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 15) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 22, 600, 44, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          } else if (gameStates?.ROWS === 16) {
            Composite.add(engine.world, [
              Bodies.rectangle(x - 20, 600, 40, 5, {
                id: col,
                label: "pocket",
                isSensor: true,
                isStatic: true,
                render: { fillStyle: "transparent" },
              }),
            ]);
          }
        }
      }
    }
  };

  const createEnvironment = (ROWS, r) => {
    createPlinkos(ROWS, r);
    Composite.add(engine.world, [
      // walls
      Bodies.rectangle(125, 330, 3, 600, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 119.85,
      }),
      Bodies.rectangle(480, 330, 3, 600, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: -119.85,
      }),

      Bodies.rectangle(265, 0, 3, 150, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),
      Bodies.rectangle(340, 0, 3, 150, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),
      Bodies.rectangle(15, 590, 2, 170, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),

      Bodies.rectangle(590, 600, 2, 190, {
        isStatic: true,
        render: { fillStyle: "transparent" },
        angle: 0,
      }),
      Bodies.rectangle(300, 600, 1000, 10, {
        isStatic: true,
        label: "resultLine",
        render: { fillStyle: "transparent" },
        isSensor: true,
      }),
    ]);
    Events.on(engine, "collisionActive", onBodyCollision);
  };

  const init = (ROWS, r) => {
    particles = {};
    plinkos = {};
    createEnvironment(ROWS, r);
  };

  const handleSound = () => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      localStorage.setItem("sound", false);
      setVolume(false);
    } else {
      localStorage.setItem("sound", true);
      setVolume(true);
    }
  };

  useEffect(() => {
    let sound = localStorage.getItem("sound");
    if (sound === "true" || sound === true) {
      setVolume(true);
    } else {
      setVolume(false);
    }
  }, []);

  const autoManualToggle = (value) => {
    localStorage.removeItem("activeInfinite");
    localStorage.removeItem("activeInfinite");
    localStorage.removeItem("hide");
    localStorage.removeItem("testMode");
    changeNumOfBets(0);
    if (value === "manual") {
      setBetAmount(50);
      setAutoManual(!autoManual);
    } else {
      setBetAmount(50);
      setAutoManual(!autoManual);
    }
    changeNumOfBets(0);
  };

  const handleChangeNumOfBets = (e) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    changeNumOfBets(parseInt(newStr));
  };

  const handleTestMode = () => {
    const msg = !testMode ? "enabled" : "disabled";
    if (msg === "disabled") {
      setBetAmount(50);
      toast.error("Test mode is " + msg, {
        toastId: "plinko-test-mode" + testMode,
      });
    } else {
      setBetAmount(0);
      toast.error("Test mode is " + msg, {
        toastId: "plinko-test-mode" + testMode,
      });
    }
    setTestMode(!testMode);
    localStorage.setItem("testmode", !testMode);
  };

  const handleBetAmount = (e, value) => {
    let newStr = e?.target?.value;
    if (newStr.indexOf("-") !== -1) {
      newStr = newStr.replace("-", "");
    }
    let parsedValue = parseFloat(newStr);
    if (parsedValue > 500) {
      parsedValue = 500;
    }
    if (value === "autoBetAmount") {
      setBetAmount(parsedValue);
    } else {
      setBetAmount(parsedValue);
    }
  };

  useEffect(() => {
    createCanvas(engine?.gravity.y);
    init(gameStates.ROWS, gameStates.plinkoradius);
    return () => {
      World.clear(engine.world, true);
      Engine.clear(engine);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStates?.ROWS]);

  useEffect(() => {
    if (risk && gameStates.ROWS) {
      changeRisk();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [risk, gameStates?.ROWS]);
  const hide = localStorage.getItem("hide");
  const isHide = hide && hide === "true" ? true : false;
  useEffect(() => {
    window.parent.postMessage(
      { type: "isGameRunning", isGameRunning: isGameRunning },
      "*"
    );
  }, [isGameRunning]);

  const handleRisk = (value) => {
    localStorage.setItem("riskType", value);
    setRisk(value);
  };

  useEffect(() => {
    socket.on("updateRoom", (data) => {
      const userid = getCookie("userid");
      const id = data?._id ? data?._id : data?.id;
      if (userid === id) {
        setUser(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTooltip = (props, profit, chance) => {
    return (
      <Tooltip id='button-tooltip' {...props} className='game-custom-tooltip'>
        <PlaygroundPopup profit={profit} chance={chance} />
      </Tooltip>
    );
  };

  return (
    <div className='plinko-game-page'>
      <div className='container'>
        <div className='plinko-grid'>
          <div className='plinko_tab_sidepanel'>
            <div className='tab-section'>
              <Tabs
                activeKey={key}
                id='uncontrolled-tab-example'
                className='mb-3'
                onSelect={(e) => handleTab(e)}>
                <Tab eventKey='Manual' title='Manual'>
                  <ManualTabToBet
                    gameStates={gameStates}
                    dropChips={dropChips}
                    changeRows={changeRows}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount}
                    risk={risk}
                    autoManualToggle={autoManualToggle}
                    autoManual={autoManual}
                    handleSound={handleSound}
                    isGameRunning={isGameRunning}
                    handleBetAmount={handleBetAmount}
                    errorMessage={errorMessage}
                    handleRisk={handleRisk}
                    testMode={testMode}
                  />
                </Tab>
                <Tab eventKey='Auto' title='Auto'>
                  <AutoTabToBet
                    gameStates={gameStates}
                    dropChips={dropChips}
                    changeRows={changeRows}
                    betAmount={betAmount}
                    setBetAmount={setBetAmount}
                    risk={risk}
                    handleRisk={handleRisk}
                    autoManualToggle={autoManualToggle}
                    autoManual={autoManual}
                    handleChangeNumOfBets={handleChangeNumOfBets}
                    numOfBets={numOfBets}
                    handleSound={handleSound}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                    newnumOfBets={newnumOfBets}
                    activeAutoBets={activeAutoBets}
                    changeBetCount={changeBetCount}
                    setActiveAutoBets={true}
                    changeNumOfBets={changeNumOfBets}
                    setIsCancel={setIsCancel}
                    isCancel={isCancel}
                    handleBetAmount={handleBetAmount}
                    errorMessage={errorMessage}
                    isGameRunning={isGameRunning}
                    handleCancel={handleCancel}
                    testMode={testMode}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className='main_section'>
            {raveMode && (
              <div className='rave-mode-box'>
                <video id='bannerVideo' autoPlay loop  muted={!volume}>
                  <source src={rave} type='video/mp4' />
                </video>
              </div>
            )}

            <div className='canvas-container'>
              <div id='plinko' />
              <div className={`pegs rows${gameStates.ROWS}`}>
                <div className='pegs_wrapper'>
                  {serverWinBoxes?.map((el, i) => (
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 250 }}
                      overlay={(props) =>
                        renderTooltip(
                          props,
                          (el?.value * betAmount - betAmount).toFixed(8),
                          el.chance
                        )
                      }>
                      <div
                        className={`peg ${""} peg${
                          bxBg[gameStates.ROWS][i]
                        } ${""}`}
                        key={i}
                        style={{
                          top: gameStates[`peg${bxBg[gameStates.ROWS][i]}`]
                            ? "10px"
                            : "0px",
                        }}
                        test={`peg${bxBg[gameStates.ROWS][i]}`}
                        test2={gameStates[`peg${bxBg[gameStates.ROWS][i]}`]}>
                        <span
                          className={`${
                            risk === "High" ? "pegtexthigh" : ""
                          } pegtext`}>
                          {el.value}x
                        </span>
                      </div>
                    </OverlayTrigger>
                  ))}
                </div>
              </div>
              {/* {winTextResult && (
                <div className="gameResultpopup">
                  <p
                    className={parseFloat(winTextResult) >= 1 ? "win" : "lose"}
                  >
                    {winMultiplierBetValue &&
                      (
                        winMultiplierBetValue * parseFloat(winTextResult)
                      ).toFixed(1)}
                  </p>
                </div>
              )} */}
              {lastMultipliers && (
                <div className='resultStatsbar-section'>
                  {lastMultipliers.map((el, i) => (
                    <p
                      key={i}
                      style={{
                        top: `${i * 40}px`,
                        opacity: 1,
                        left: 0,
                        transition: "all ease 0.9s",
                        backgroundColor: el?.backgroundColor,
                        color: el?.color,
                      }}
                      className={`${
                        toggleAnimation && lastMultipliers?.length - 1 === 0
                          ? "wow animate__animated animate__slideInDown"
                          : ""
                      }`}>
                      {el?.multiplierValue}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>

          <RightSidePanel
            showPanel={showPanel}
            handlePanel={handlePanel}
            handleRave={handleRave}
            handleBallColor={handleBallColor}
            handleSpeedChange={handleSpeedChange}
            inputRangeSpeed={inputRangeSpeed}
            isGameRunning={isGameRunning}
          />
        </div>

        {/* {user && ( */}
        <div className='plinko-game-footer'>
          <div className='auto-bet auto-bet-mode'>
            <p>Just for fun</p>
            <div className={`toggle-section`}>
              <input
                type='checkbox'
                id='lb3'
                disabled={isGameRunning || isHide}
                checked={testMode}
                onChange={handleTestMode}
              />
              <Form.Label
                htmlFor='lb3'
                className={`${isGameRunning || isHide ? "disabled" : ""}`}
              />
            </div>

            <div className='plinko-game-btns'>
              <div className='volume-btn1'>
                {volume ? (
                  <i
                    className='las la-volume-up'
                    role='presentation'
                    onClick={handleSound}
                  />
                ) : (
                  <i
                    className='las la-volume-off'
                    role='presentation'
                    onClick={handleSound}
                  />
                )}
              </div>

              {/* <div className="fairness-btn" onClick={handlePanel}>
                <i className="las la-cog" />
              </div> */}

              <div className='fairness-btn' onClick={() => setGameInfo(true)}>
                <BillIcon />
              </div>

              {/* <div className="fairness-btn" onClick={handlestatsShow}>
        <span>Live stats</span>
      </div> */}
            </div>
          </div>

          <div className='fairness-btn' onClick={handleShow}>
            <span>Fairness</span>
          </div>
        </div>
        {/* )} */}

        <div>
          <audio className='audio-click' muted={!volume}>
            <source src={click}></source>
          </audio>
          <audio className='audio-win' muted={!volume}>
            <source src={win}></source>
          </audio>
        </div>

        <Modal
          show={fairness}
          onHide={handleCloseFairness}
          centered
          className='fairness-popup plinko-game-page login-popup'>
          <Modal.Header closeButton>
            <Modal.Title>Fairness</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FainessPopup />
          </Modal.Body>
        </Modal>
        <Modal
          show={stats}
          onHide={handlestatsClose}
          centetransparent
          className='fairness-popup'
          size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Live Stats</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LiveStats firstTimeBalance={firstTimeBalance} />
          </Modal.Body>
        </Modal>

        <Modal
          className='plinko-game-page login-popup game-info-popup '
          size='lg'
          show={gameInfo}
          onHide={handleCloseGameInfo}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <BillIcon /> Game Rules
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GameInfoPopUp />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default GameEngine;

export const BillIcon = () => {
  return (
    <svg
      fill='#a0a0a0'
      viewBox='0 0 64 64'
      className='svg-icon '
      width='24px'
      height='24px'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22.8 8.547h36.774c0 .028.026.054.026.054h-.016a6.75 6.75 0 0 0-6.384 6.586v33.04l-.002-.02a6.8 6.8 0 0 1-6.786 7.246 6.8 6.8 0 0 1-6.786-7.226v-3.094H16V15.347a6.8 6.8 0 0 1 6.8-6.8Zm-.828 27.894h15.494V31.8H21.972v4.64Zm.002-12.4h24.8V19.4h-24.8v4.64Zm38.052-13.574h-.052A3.974 3.974 0 0 0 56 14.44v6.426h8V14.44a3.974 3.974 0 0 0-3.974-3.974ZM36.8 48.256H0a6.8 6.8 0 0 0 6.8 6.8h34.026c-2.694 0-4.026-3.04-4.026-6.8Z'></path>
    </svg>
  );
};

import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { authInstance } from "../../config/axiosConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const Reset = ({ token }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword.length <= 4) {
      toast.error("Password must be longer than 4 characters", {
        toastId: "charLength",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match", { toastId: "passmatch" });
      return;
    }

    try {
      setLoader(true);
      const response = await authInstance().post(
        `/reset-password?token=${token}`,
        {
          password: newPassword,
        }
      );
      setLoader(false);

      if (response.data.msg) {
        toast.success(response.data.msg, {
          toastId: "resetsuccess",
        });
        navigate("/");
      } else {
        toast.error("Failed to reset password", { toastId: "resetFailed" });
      }
    } catch (error) {
      setLoader(false);
      toast.error("An error occurred. Please try again.", {
        toastId: "errorOccureed",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  return (
    <div className='login total_bet_input'>
      <div className='login-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='login-form'>
                <h1>Reset</h1>
                <p>Please enter your password to reset</p>
                <div className='login-box'>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId='formBasicFirst'>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='Enter your password'
                        name='newPassword'
                        value={newPassword}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId='formBasicFirst'>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type='password'
                        placeholder='Confirm password'
                        name='confirmPassword'
                        value={confirmPassword}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <div className='login-button'>
                      <Button
                        type='submit'
                        className='btn-gold plinko-bet-btn btn btn-contained btn-medium'>
                        {loader ? (
                          <Spinner
                            animation='border'
                            size='sm'
                            className='accept-btn-show-btn'
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Form>
                  <p className='already' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;

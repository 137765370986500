import React, { useState } from "react";
import "./RightSidePanel.css";
import { Form } from "react-bootstrap";
import { PARTICLE } from "../plinko/constants/bodies";

const colors = [
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
];

const RightSidePanel = ({showPanel, handlePanel, handleRave,handleBallColor,handleSpeedChange,inputRangeSpeed,isGameRunning}) => {
  const [selectedColor, setSelectedColor] = useState(localStorage.getItem("ballColor") || PARTICLE.FILL);

  const handleColorChange = (color) => {
    if(isGameRunning) return
    localStorage.setItem('ballColor',color)
    setSelectedColor(color);
    handleBallColor(color);
  };
  return (
    <div className={`plinko-sidepanel ${showPanel ? "expand" : ""}`}>

      <div className="btn-close" onClick={handlePanel} role="presentation"></div>
      <div className="plinko-sidepanel-content">
        <div className="ball-color">
          {" "}
          <label for="customRange1" className="form-label">
            Ball Color
          </label>
          <div className="ball-color-section">
            {colors.map((color) => (
              <div className="color-box" role="presentation" onClick={() => handleColorChange(color)}>
                <div
                  key={color}
                  className={`color-ball ${
                    selectedColor === color ? "selected" : ""
                  }`}
                  style={{ backgroundColor: color }}
                >
                  {selectedColor === color && (
                    <span className="checkmark">✓</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* ball speed  */}

      {/* <div className="ball-speed">
        <label for="customRange1" className="form-label">
          Ball Speed
        </label>
        <input
          type="range"
          className="styled-slider"
          id="customRange1"
          disabled={isGameRunning}
          onChange={handleSpeedChange}
          value={inputRangeSpeed}
        />
        <div className="range">
          <p>Slow</p>
          <p>Fast</p>
        </div>
      </div> */}

      {/* rave mode  */}

      <div className="rave-mode">
        <label for="customRange1" className="form-label">
          Rave Mode
        </label>
        <div className={`toggle-section`}>
          <input type="checkbox" id="rave" onChange={handleRave}/>
          <Form.Label htmlFor="rave" />
        </div>
      </div>
    </div>
  );
};

export default RightSidePanel;

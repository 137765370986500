import React from "react";

const Overview = () => {
  return (
    <div className="overview">
      <h2>Solving the Trust Issue with Online Gambling</h2>

      <p>
        The underlying concept of provable fairness is that players have the
        ability to prove and verify that their results are fair and
        unmanipulated. This is achieved through the use of a{" "}
        <a
          href="https://en.wikipedia.org/wiki/Commitment_scheme"
          target="_blank"
          rel="noreferrer"
        >
          commitment scheme <LinkIcon />
        </a>{" "}
        , along with cryptographic hashing.
      </p>

      <p>
        The commitment scheme is used to ensure that the player has an influence
        on all results generated. Cryptographic hashing is used to ensure that
        the casino also remains honest to this commitment scheme. Both concepts
        combined creates a trust-less environment when gambling online.
      </p>

      <p>This is simplified in the following representation:</p>

      <div className="code-container">
        <h3>fair result = operators input (hashed) + customers input</h3>
      </div>

      <h2>3rd Party Verification</h2>
      <p>
        Plinko can be verified both here and via
        3rd party websites who have also open sourced the verification
        procedure. You can find them via a google search, or simply check out
        some of these that have been put together by our community:
      </p>

      {/* <ul>
        <li>https://provablyfair.me </li>
        <li> https://stakestats.net</li>
      </ul> */}

      {/* <h2>Crypto Gambling Foundation</h2>
      <p>
        Plinko is a verified operator on the Crypto Gambling Foundation network.
        This foundation aims to uphold the highest standard of provably fair
        gambling and we are proud to be a part of their network. You can find
        further information and insights about provable fairness and the power
        it has in this industry, check out the Crypto Gambling Foundation via
        their website:{" "}
        <a href="https://cryptogambling.org/" target="_blank" rel="noreferrer">
          https://cryptogambling.org/ <LinkIcon />
        </a>
      </p> */}
    </div>
  );
};

export default Overview;

export const LinkIcon = () => {
  return (
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_429_11072)">
        <path
          d="M11 3.99994H4V17.9999C4 19.1045 4.89543 19.9999 6 19.9999H18C19.1046 19.9999 20 19.1045 20 17.9999V12.9999"
          stroke="#fff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 14.9999L20 3.99994"
          stroke="#fff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 3.99994H20V8.99994"
          stroke="#fff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_429_11072">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};


export const bxBg = {
    16: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    15: [1, 2, 3, 4, 5, 6, 7, 9, 0, 11, 12, 13, 14, 15, 16, 17],
    14: [1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 15, 16, 17],
    13: [1, 2, 3, 4, 5, 6, 9, 0, 12, 13, 14, 15, 16, 17],
    12: [1, 2, 3, 4, 5, 6, 9, 12, 13, 14, 15, 16, 17],
    11: [1, 2, 3, 4, 5, 9, 0, 13, 14, 15, 16, 17],
    10: [1, 2, 3, 4, 5, 9, 13, 14, 15, 16, 17],
    9: [1, 2, 3, 4, 9, 0, 14, 15, 16, 17],
    8: [1, 2, 3, 4, 9, 14, 15, 16, 17],
  };
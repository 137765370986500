import axios from "axios";
import { getCookie } from "../components/utils/cookies";
import { landingServer } from "../config/keys";



export const plinkoInstance = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  axios.create({
    baseURL: `${landingServer}/v1/plinko`,
    headers: {
      Authorization: `Bearer ${getCookie("token")}`,
    },
    timeout: 1000 * 20,
  });

export const userInstance = () => axios.create({
  baseURL: `${landingServer}/v1/users`,
  headers: { Authorization: `Bearer ${getCookie("token")}` },
});

export const authInstance = () => axios.create({
  baseURL: `${landingServer}/v1/auth`,
  headers: { Authorization: `Bearer ${getCookie("token")}` },
});


import React from "react";
import "./playgroundpopup.css";

const PlaygroundPopup = ({ profit, chance }) => {
  return (
    <div className="d-flex playground-popup align-items-center">
      <div className="w-50 d-flex flex-column ">
        <div className="d-flex justify-content-between">
          <h6>Profit on Win</h6>
          {/* <p>$0.0</p> */}
        </div>

        <div className="pg-popup-box">
          <h2>{profit || "$0000000"}</h2>
        </div>
      </div>

      <div className="w-50 d-flex flex-column">
        <h6>Chance</h6>
        <div className="pg-popup-box">
          <h2>{chance || "$0000000"}</h2>
          <PercentageIcon />
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default PlaygroundPopup;

export const PercentageIcon = () => {
  return (
    <svg
      fill="#fff"
      width="20px"
      height="20px"
      viewBox="-3 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      class="cf-icon-svg"
    >
      <path d="M2.865 8.14a2.553 2.553 0 1 1 2.552-2.552 2.555 2.555 0 0 1-2.552 2.553zm0-1.582a.97.97 0 1 0-.97-.97.97.97 0 0 0 .97.97zm7.942-1.991L3.914 14.886a1.03 1.03 0 0 1-1.712-1.143l6.893-10.32a1.03 1.03 0 0 1 1.712 1.144zm1.88 8.215a2.553 2.553 0 1 1-2.552-2.552 2.555 2.555 0 0 1 2.553 2.552zm-1.582 0a.97.97 0 1 0-.97.97.97.97 0 0 0 .97-.97z" />
    </svg>
  );
};

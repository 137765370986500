export const coinPayData =  [
    {
        "coinId": 1282,
        "symbol": "USDC",
        "logoUrl": "https://resource.cwallet.com/token/icon/usdc.png",
        "status": "Normal",
        "networks": {
            "ARETH": {
                "chain": "ARETH",
                "chainFullName": "Arbitrum One",
                "contract": "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "AVAX": {
                "chain": "AVAX",
                "chainFullName": "Avalanche C-Chain",
                "contract": "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "BSC": {
                "chain": "BSC",
                "chainFullName": "Binance Smart Chain",
                "contract": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "ETH": {
                "chain": "ETH",
                "chainFullName": "Ethereum",
                "contract": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "POLYGON": {
                "chain": "POLYGON",
                "chainFullName": "Polygon",
                "contract": "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "SOL": {
                "chain": "SOL",
                "chainFullName": "Solana",
                "contract": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "TRX": {
                "chain": "TRX",
                "chainFullName": "Tron Blockchain",
                "contract": "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            }
        }
    },
    {
        "coinId": 1891,
        "symbol": "TETH",
        "logoUrl": "https://resource.cwallet.com/token/icon/ETH.png",
        "status": "Normal",
        "networks": {
            "ETH_SEPOLIA": {
                "chain": "ETH_SEPOLIA",
                "chainFullName": "SepoliaETH",
                "contract": "11155111",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.001",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            }
        }
    },
    {
        "coinId": 1155,
        "symbol": "BTC",
        "logoUrl": "https://resource.cwallet.com/token/icon/btc.png",
        "status": "Normal",
        "networks": {
            "BTC": {
                "chain": "BTC",
                "chainFullName": "Bitcoin",
                "contract": "BTC",
                "precision": 8,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.00008",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            }
        }
    },
    {
        "coinId": 1161,
        "symbol": "ETH",
        "logoUrl": "https://resource.cwallet.com/token/icon/ETH.png",
        "status": "Normal",
        "networks": {
            "ARETH": {
                "chain": "ARETH",
                "chainFullName": "Arbitrum One",
                "contract": "42161",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.00000043",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "BASE": {
                "chain": "BASE",
                "chainFullName": "Base Blockchain",
                "contract": "8453",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.00000043",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "BSC": {
                "chain": "BSC",
                "chainFullName": "Binance Smart Chain",
                "contract": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.00000043",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "ETH": {
                "chain": "ETH",
                "chainFullName": "Ethereum",
                "contract": "1",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.00000043",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "OPTIMISM": {
                "chain": "OPTIMISM",
                "chainFullName": "Optimism ",
                "contract": "10",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.00000043",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            }
        }
    },
    {
        "coinId": 1186,
        "symbol": "SOL",
        "logoUrl": "https://resource.cwallet.com/token/icon/sol.png",
        "status": "Normal",
        "networks": {
            "BSC": {
                "chain": "BSC",
                "chainFullName": "Binance Smart Chain",
                "contract": "0x570a5d26f7765ecb712c0924e4de545b89fd43df",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.001",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "SOL": {
                "chain": "SOL",
                "chainFullName": "Solana",
                "contract": "SOL",
                "precision": 9,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.001",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            }
        }
    },
    {
        "coinId": 1280,
        "symbol": "USDT",
        "logoUrl": "https://resource.cwallet.com/token/icon/usdt.png",
        "status": "Normal",
        "networks": {
            "ARETH": {
                "chain": "ARETH",
                "chainFullName": "Arbitrum One",
                "contract": "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "BSC": {
                "chain": "BSC",
                "chainFullName": "Binance Smart Chain",
                "contract": "0x55d398326f99059ff775485246999027b3197955",
                "precision": 18,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "ETH": {
                "chain": "ETH",
                "chainFullName": "Ethereum",
                "contract": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "OPTIMISM": {
                "chain": "OPTIMISM",
                "chainFullName": "Optimism ",
                "contract": "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "POLYGON": {
                "chain": "POLYGON",
                "chainFullName": "Polygon",
                "contract": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "SOL": {
                "chain": "SOL",
                "chainFullName": "Solana",
                "contract": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "TON": {
                "chain": "TON",
                "chainFullName": "Ton Blockchain",
                "contract": "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            },
            "TRX": {
                "chain": "TRX",
                "chainFullName": "Tron Blockchain",
                "contract": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
                "precision": 6,
                "canDeposit": true,
                "canWithdraw": true,
                "minimumDepositAmount": "0",
                "minimumWithdrawAmount": "0.025",
                "maximumWithdrawAmount": "0",
                "isSupportMemo": false
            }
        }
    }
]
import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

function AutoTabToBet({
  setBetAmount,
  betAmount,
  isPlaceBet,
  handleDrop,
  dropChips,
  changeRows,
  gameStates,
  risk,
  handleChangeNumOfBets,
  numOfBets,
  handleBetAmount,
  errorMessage,
  isCancel,
  isGameRunning,
  handleCancel,
  handleRisk,
  testMode,
}) {
  const hide = localStorage.getItem("hide");
  const handleBet = (amt, type) => {
    if (type === "half") {
      if (amt < 50) {
        setBetAmount(50);
      } else {
        setBetAmount(amt);
      }
    }
    if (type === "double") {
      setBetAmount(amt > 500 ? 500 : amt);
    }
    if (type === "4x") {
      setBetAmount(amt > 500 ? 500 : amt);
    }
    if (type === "max") {
      setBetAmount(500);
    }
  };

  const isHide = hide === "true" || hide === true ? true : false;

  return (
    <div className='plinko-place-bet'>
      <div className='total_bet'>
        <div className='total_bet_input'>
          <p>Bet Amount</p>
          <input
            type='number'
            disabled={isHide || isGameRunning}
            value={betAmount}
            onChange={(e) => handleBetAmount(e, "autoBetAmount")}
          />
          <div className='multiplier-btn'>
            <Button
              className='multiplier'
              size='medium'
              color='primary'
              variant='contained'
              disabled={isHide || isGameRunning}
              onClick={() => handleBet(betAmount / 2, "half")}>
              ½
            </Button>
            <Button
              className='multiplier'
              size='medium'
              color='primary'
              variant='contained'
              disabled={isHide || isGameRunning}
              onClick={() => handleBet(betAmount * 2, "double")}>
              2×
            </Button>
            {/* <Button
            className="multiplier"
            size="medium"
            color="primary"
            variant="contained"
            disabled={isHide || isGameRunning}
            onClick={() => handleBet(betAmount * 4, '4x')}
          >
            4×
          </Button>
          <Button
            className="multiplier"
            size="medium"
            color="primary"
            variant="contained"
            disabled={isHide || isGameRunning}
            onClick={() => handleBet(500, 'max')}
          >
            MAX
          </Button> */}
          </div>
          {errorMessage && <p className='text-danger'>{`${errorMessage}`}</p>}
        </div>

        <div className='total_bet_input'>
          <p>Number of Bets</p>
          <input
            type='number'
            min={0}
            disabled={isHide || isGameRunning}
            value={numOfBets}
            onChange={handleChangeNumOfBets}
          />
          {numOfBets <= 0 && <i class='bi bi-infinity text-light'></i>}
        </div>

        <div className='no-of-rows'>
          <Dropdown>
            <p>Risk</p>
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              disabled={isHide || isGameRunning}>
              {risk}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleRisk("Low")}>
                Low
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleRisk("Medium")}>
                Medium
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleRisk("High")}>
                High
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <p>Row</p>
            <Dropdown.Toggle
              variant='success'
              id='dropdown-basic'
              disabled={isHide || isGameRunning}>
              {gameStates?.ROWS}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeRows(8)}>8</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(9)}>9</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(10)}>10</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(11)}>11</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(12)}>12</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(13)}>13</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(14)}>14</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(15)}>15</Dropdown.Item>
              <Dropdown.Item onClick={() => changeRows(16)}>16</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='auto-bet-section'>
          {/* <div className="testmode">
            <div className="auto-bet">
              <p>Auto Bet</p>
              <div className={`toggle-section`}>
                <input
                  type="checkbox"
                  id="lb2"
                  disabled={isHide || isGameRunning}
                  checked={autoManual}
                  onChange={autoManualToggle}
                />
                <Form.Label htmlFor="lb2" className={`${isHide || isGameRunning ? "disabled" : ""}`} />
              </div>
            </div>
          </div> */}
          <div className='place-bet-section'>
            {isPlaceBet ? (
              <>
                <Button
                  className='btn-gold plinko-bet-btn'
                  size='medium'
                  color='primary'
                  variant='contained'
                  disabled={!testMode && betAmount === 0}
                  onClick={() => handleDrop()}>
                  Drop
                </Button>
              </>
            ) : (
              <>
                {!isCancel ? (
                  <Button
                    className='btn-gold plinko-bet-btn'
                    size='medium'
                    color='primary'
                    variant='contained'
                    disabled={!testMode && betAmount === 0}
                    onClick={() => dropChips("autoBet")}>
                    Start Autobet
                  </Button>
                ) : (
                  <Button
                    onClick={handleCancel}
                    className='btn-gold plinko-bet-btn'
                    size='medium'
                    color='primary'
                    variant='contained'>
                    <span>Stop Autobet</span>
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

AutoTabToBet.defaultProps = {
  setBetAmount: () => {},
  betAmount: 0,
  dropChips: () => {},
  handleDrop: () => {},
  isPlaceBet: false,
};

AutoTabToBet.propTypes = {
  setBetAmount: PropTypes.func,
  betAmount: PropTypes.number,
  dropChips: PropTypes.func,
  handleDrop: PropTypes.func,
  isPlaceBet: PropTypes.bool,
  changeRows: PropTypes.func.isRequired,
  lines: PropTypes.number.isRequired,
  setRisk: PropTypes.func.isRequired,
  risk: PropTypes.string,
  autoManualToggle: PropTypes.func,
  autoManual: PropTypes.bool,
  handleChangeNumOfBets: PropTypes.func,
  numOfBets: PropTypes.number,
};

export default AutoTabToBet;

import React, { useEffect, useState } from "react";
import "./affiliate.css";
import {
  Button,
  Col,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip,
} from "react-bootstrap";

import {clientPath} from "../../config/keys";

import copy from "../../assets/images/game/copy-icon.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { userInstance } from "../../config/axiosConfig";


const Affiliate = () => {
  const [affiliateList, setAffiliateList] = useState([]);
  const [referralCode, setReferralCode] = useState("");
  const [totalEarned, setTotalEarned] = useState(0);
  const [list , setList] = useState([]);
  

  const getAffiliateOverview = async () => {
    const res = await userInstance().get("/get-affiliate-list", {
      withCredentials: true,
      credential: "include",
    });
   console.log("res", res.data);
   if (res.data?.code===200) {
    setAffiliateList(res.data?.referredList);
    setReferralCode(res.data?.referralCode); 
    setList(res.data?.result.length > 0 ? res.data?.result[0].referList : []);
    const earned =res.data?.result.length > 0 ? res.data?.result[0].referList?.reduce((a, b) => a + b?.totalAmount || 0, 0) : 0;
    setTotalEarned(earned);
    
  }}; 
  useEffect(() => {
    getAffiliateOverview();  
  }, []);

  return (
    <div className="provably-fair-page settings-page">
      <div className="container">
        <div className="d-flex justify-content-between">
          <h2 className="provably-fair-title">
            {" "}
            <AffiliateIcon /> Affiliate Program
          </h2>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="overview">
          <Row>
            <Col sm={2} className="custom-tab-container">
              <Nav variant="pills" className="flex-column custom-tab-nav">
                <Nav.Item>
                  <Nav.Link eventKey="overview">Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="commission">Commission</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="security">Details</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={10}>
              <div className="provably-fair-content">
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <AffiliateOverview  
                    referralCode={referralCode}  
                    totalEarned={totalEarned}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="commission">
                    <AffiliateCommission  />
                  </Tab.Pane>
                  <Tab.Pane eventKey="security">
                    <AffiliateDetails
                      affiliateList={affiliateList} 
                    list={list}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Affiliate;

export const AffiliateOverview = ({referralCode , totalEarned}) => {
  const [copied, setCopied] = useState("");

  const referralLink = `${clientPath}/?ref=${referralCode}`;
  const handleCopy = (value) => {
    setCopied(value);
  };

  return (
    <div className="affiliate-overview my-2">
      <div className="d-flex gap-3 mb-3">
        <div className="col-md-4 affiliate-card ">
          <h2>Affiliate ID</h2>
          <p>{referralCode}</p>
        </div>

        <div className="col-md-4 affiliate-card">
          <h2>Total Earned</h2>
          <p>{totalEarned}</p>
        </div>
      </div>
      <p>
        Refer our brand and earn money through participating in Plinko
        Affiliate Program! You will receive a commission for users who register
        and play on Plinko through your links.
      </p>

      <p>
        Being a Plinko affiliate means you will earn commission for all bets
        placed on Plinko - which unlike traditional
        affiliate programs, means that despite if they win or lose, you still
        make the same commission!
      </p>

      <div className="col-md-4">
        <Form.Group>
          <Form.Label className="mt-3 mb-2">Referral Link</Form.Label>
          <div className="plinko-input">
            <Form.Control type="text" value={referralLink} disabled />
            <div className="plinko-input-btn">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) =>
                  renderTooltip(props, copied, "referralLink")
                }
              >
                <CopyToClipboard
                  onCopy={() => handleCopy("referralLink")}
                  text={referralLink}
                >
                  <Button className="plinko-btn-copy">
                    <img src={copy} alt="copy" />
                  </Button>
                </CopyToClipboard>
              </OverlayTrigger>
            </div>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export const AffiliateDetails = ({affiliateList , list}) => {
 
  // const AffiliateData = [
  //   {
  //     id: 1,
  //     avatar:
  //       "https://thumbs.dreamstime.com/b/d-icon-avatar-cartoon-character-man-businessman-business-suit-looking-camera-isolated-transparent-png-background-277029050.jpg",
  //     name: "John Doe",
  //     total_commission: "Rs. 1000000",
  //     joined_at: "12-06-2021",
  //   },
  //   {
  //     id: 2,
  //     avatar:
  //       "https://thumbs.dreamstime.com/b/d-icon-avatar-cartoon-character-man-businessman-business-suit-looking-camera-isolated-transparent-png-background-277029050.jpg",
  //     name: "John Doe",
  //     total_commission: "Rs. 1004660",
  //     joined_at: "12-06-2021",
  //   },
  //   {
  //     id: 3,
  //     avatar:
  //       "https://thumbs.dreamstime.com/b/d-icon-avatar-cartoon-character-man-businessman-business-suit-looking-camera-isolated-transparent-png-background-277029050.jpg",
  //     name: "John Doe",
  //     total_commission: "Rs. 1004660",
  //     joined_at: "12-06-2021",
  //   },
  //   {
  //     id: 4,
  //     avatar:
  //       "https://thumbs.dreamstime.com/b/d-icon-avatar-cartoon-character-man-businessman-business-suit-looking-camera-isolated-transparent-png-background-277029050.jpg",
  //     name: "John Doe",
  //     total_commission: "Rs. 1004660",
  //     joined_at: "12-06-2021",
  //   },
  // ];
  return (
    <div className="affiliate-details mt-2  ">
      <h2>Affiliate Details</h2>
      <div className="table-responsive my-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ textAlign: "left" }}>Sr No</th>

              <th style={{ textAlign: "center" }}>User</th>
              <th style={{ textAlign: "center" }}>Total Commission</th>
              <th style={{ textAlign: "center" }}>Joined At</th>
            </tr>
          </thead>

          <tbody>
            { list.length > 0 && list?.map((item , i) => (
              <tr>
                <td style={{ textAlign: "left" }}>{i+1}</td>

                <td style={{ textAlign: "center" }}>
                  {" "}
                  <img src="https://thumbs.dreamstime.com/b/d-icon-avatar-cartoon-character-man-businessman-business-suit-looking-camera-isolated-transparent-png-background-277029050.jpg" alt="avatar" />
                   {item?.referredUser?.name}
                </td>
                <td style={{ textAlign: "right" }}>{item?.totalAmount?.toFixed(2) || 0}</td>
                <td style={{ textAlign: "right" }}>{item?.referredUser?.createdAt?.split("T")[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AffiliateCommission = () => {
 
  return (
    <div className="overview">
    <h2>Plinko</h2>

    <p>
    All of our games have a different house edge. You can derive your commission using the following formula:
    </p>

    <div className="code-container">
      <h3>(Edge as decimal * wagered / 2) * commission rate</h3>
    </div>

   
  </div>
  );
};


const renderTooltip = (props, copied, type) => (
  <Tooltip id="button-tooltip" {...props} placement="top">
    {copied === type ? "Copied" : "Copy"}
  </Tooltip>
);

const AffiliateIcon = () => {
  return (
    <svg
      fill="#a0a0a0"
      viewBox="0 0 64 64"
      class="svg-icon "
      height="20px"
      width="20px"
    >
      <path d="M55.543 19.096a8.353 8.353 0 0 0-8.296 7.437l-.004.038-6.464 1.73a8.172 8.172 0 0 0-3.56-2.641l-.057-.018v-7.05c3.127-1.262 5.293-4.272 5.293-7.79 0-4.628-3.75-8.38-8.38-8.38a8.378 8.378 0 0 0-8.378 8.38 8.383 8.383 0 0 0 5.237 7.771l.055.02v6.837a7.744 7.744 0 0 0-1.959 1.103l.018-.014-12.29-6.703a6.611 6.611 0 0 0 0-.736v.018c0-4.629-3.75-8.38-8.379-8.38A8.378 8.378 0 0 0 0 19.098a8.378 8.378 0 0 0 8.38 8.379 8.183 8.183 0 0 0 5.538-2.16l-.006.005 11.916 6.57a7.87 7.87 0 0 0 .002 1.415l-.002-.03a8.528 8.528 0 0 0 .66 3.276l-.021-.056-9.311 9.043a8.107 8.107 0 0 0-3.43-.772h-.002l-.16-.002a8.405 8.405 0 0 0-8.405 8.406 8.405 8.405 0 0 0 8.405 8.405 8.405 8.405 0 0 0 8.406-8.405 7.947 7.947 0 0 0-.63-3.35l.02.052 9.31-9.044a8.057 8.057 0 0 0 3.414.744h.02a7.938 7.938 0 0 0 2.318-.36l-.056.015 2.66 4.124a8.25 8.25 0 0 0-1.49 4.734v.026a8.405 8.405 0 0 0 8.405 8.405 8.405 8.405 0 0 0 8.405-8.405 8.405 8.405 0 0 0-8.405-8.405 7.84 7.84 0 0 0-1.836.223l.054-.012-2.927-4.443a7.84 7.84 0 0 0 1.114-3.262l.003-.035 6.49-1.73A8.378 8.378 0 0 0 64 27.527a8.377 8.377 0 0 0-8.378-8.38h-.082.004v-.051Z"></path>
    </svg>
  );
};

import React, { useState, useEffect } from "react";
import "./header.css";
import { Dropdown, Modal } from "react-bootstrap";
import Login from "../login/login";
import Register from "../register/register";
import { useAuthStore } from "../../store/auth";
import { handleLogout } from "../utils/Api";
import WalletModal from "../wallet/walletModal";
import { socket } from "../../config/socket";
import { getCookie } from "../utils/cookies";
import { toast } from "react-toastify";
import Forgot from "../forgot/forgot";
import Reset from "../forgot/reset";
import { Link, useLocation, useNavigate } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Header = () => {
  const query = useQuery();
  const token = query.get("reset-password-token");
  const { user, setUser, setIsShow, isShow } = useAuthStore((state) => state);
  const [wallet, setWallet] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const location = useLocation();

  // const [user, setUser] = useState(true);

  const handleModal = (e) => {
    console.log("Clii", e);
    setIsShow(e);
  };

  const handleShowWallet = () => {
    setWallet(true);
  };
  const handleHideWallet = () => {
    setWallet(false);
  };

  useEffect(() => {
    socket.on("ccpayment", (data) => {
      const userid = getCookie("userid");
      const id = data.userId;
      if (userid === id) {
        if (data.type === "Deposit") {
          toast.success("Deposit Successful");
        } else if (data.type === "Withdrawal") {
          setShowSpinner(false);
          toast.success("Withdrawal Succesful");
        }
      }
    });
  }, []);

  useEffect(() => {
    const ref = query.get("ref");
    console.log("ref", ref);

    if (ref) {
      setIsShow("register");
      // setUser({referralCode:ref});
      console.log(ref);
    }
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const handleLinkClick = (e, path) => {
    if (!user) {
      e.preventDefault(); // Prevent navigation
      handleModal("login"); // Replace with your login pop-up logic
    } else {
      navigate(path); // Navigate to the desired page if logged in
    }
  };

  return (
    <div className='header'>
      <div className='logo  links '>
        <h2>
          <Link to='/'>Plinko</Link>
        </h2>
        <Link
          className={location.pathname === "/affiliate" ? "active-menu" : ""}
          to='/affiliate'
          onClick={(e) => handleLinkClick(e, "/affiliate")}>
          Affiliates
        </Link>
        <Link
          className={
            location.pathname === "/provably-fair" ? "active-menu" : ""
          }
          to='/provably-fair?tab=overview'
          onClick={(e) => handleLinkClick(e, "/provably-fair?tab=overview")}>
          Provably Fair
        </Link>
      </div>

      {!user && (
        <div className='header-buttons'>
          <button onClick={() => handleModal("login")}>Login</button>
          <button onClick={() => handleModal("register")}>Register</button>
        </div>
      )}

      {user && (
        <div className='wallet-section'>
          <div className='wallet-body'>
            <p>${user?.wallet}</p>
            <button
              className='btn-gold plinko-bet-btn btn btn-contained btn-medium'
              onClick={handleShowWallet}>
              {""} Wallet
            </button>
          </div>
        </div>
      )}

      {user && (
        <div className='profile-section'>
          <UserIcon />
          <Dropdown>
            <Dropdown.Toggle>
              <p>{user?.firstName}</p>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/profile'>Profile</Dropdown.Item>
              <Dropdown.Item>
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  to='/my-bets'>
                  My Bets
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  to='/settings'>
                  Settings
                </Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout(user, setUser)}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <Modal
        show={isShow === "login"}
        onHide={() => handleModal(false)}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        className='login-popup'
        centered>
        {/* render login component */}
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Login handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal
        show={isShow === "register"}
        onHide={() => handleModal(false)}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        className='login-popup'
        centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Register handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal className='bch-game-popup' show={wallet} onHide={handleHideWallet}>
        <Modal.Body>
          <WalletModal
            showSpinner={showSpinner}
            setShowSpinner={setShowSpinner}
            handleHideWallet={handleHideWallet}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={isShow === "forgot"}
        onHide={() => handleModal(false)}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        className='login-popup'
        centered>
        {/* render login component */}
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Forgot handleModal={handleModal} />
        </Modal.Body>
      </Modal>

      <Modal
        show={token}
        onHide={() => handleModal(false)}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        className='login-popup'
        centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Reset token={token} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;

const UserIcon = () => {
  return (
    <svg
      width='800px'
      height='800px'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z'
        stroke='#a0a0a0'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z'
        stroke='#a0a0a0'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
